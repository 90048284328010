import React, { useState } from 'react';
import css from './Store.module.css';
import { Calendar } from 'react-multi-date-picker';
import { Button, NamedLink } from '..';
import classNames from 'classnames';
import storeImg from '../../assets/skate.jpeg';
import CustomListingCardComponent from './CustomListingCard';
//import { FacebookShare, LinkedinShare, TwitterShare, WhatsappShare } from 'react-share-kit';

const StoreComponent = props =>{

    const [showSettings, setShowSettings] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [value, setValue] = useState(new Date());
    const [valueTo, setValueTo] = useState(new Date());
    const [showSocial, setShowSocial] = useState(false);

    const {entities,listingEntities,currentUser} = props;
    const currentUserId = currentUser?.id?.uuid;
    const {ownListing,image} = entities;
    const marketListing = listingEntities?.listing;
    const marketImage = listingEntities?.image;

    const handleInputClicked = ()=>{
        setShowCalendar(!showCalendar);
        console.log('Clicking      -------');
    }

    const handleSubmitFrom = (event)=>{
        console.log(value);
        setValue(event);
        console.log("----------MMMMMMMMMMMMM--------------");
    };
    
    const handleSubmitTo = (event)=>{
    
        setValueTo(event);
        console.log(valueTo);
        console.log("----------TTTTTTTTTTTT--------------");
    };

    const handleShowSocial = (event)=>{
        setShowSocial(!showSocial);
    }

    let storeName = "";
    let biography = "";
    let storeImage = "";
    let storeProfileImage = "";
    if(entities !== undefined && entities.hasOwnProperty("listing")){
        storeName = entities.listing[Object.keys(entities.listing)[0]].attributes.publicData.storeName;
        biography = entities.listing[Object.keys(entities.listing)[0]].attributes.publicData.biography;
        storeImage = entities.listing[Object.keys(entities.listing)[0]].attributes.publicData.storeImage?.url;
        storeProfileImage = entities.listing[Object.keys(entities.listing)[0]].attributes.publicData.storeProfileImage?.url;
    }
   
    
  return (
    <>
            <div className= {classNames("container d-flex d-flex-row my-5 gap-5 "+css.containerMain)}>
                
               <div className={classNames('col-sm-2 col-md-2',css.aside)} >
                    <h4>CATEGORIES</h4>
                    <NamedLink name='NewsPage'>
                        Dance Xchange News
                    </NamedLink>

                    <NamedLink name='HowToPage'>
                        HOW TO:
                    </NamedLink>

                    <hr/>

                    <h4>RECENT COMMENTS</h4>
               </div>
               <div className={classNames('col-sm-10 col-md-10',css.conBorder)} >
                    <div className={css.imgCon}>
                        <img src={storeImage}/>
                    </div>
                    <div className={css.overlay}>
                        <img className={css.resize} src={storeProfileImage}/>
                        <div className={css.captionText}>
                            <h3 className={css.textShadow}>{storeName}</h3>
                            <div className={css.svgCon}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                </svg>
                                <p className={css.noMargin}>No rating found yet</p>
                            </div>
                        </div>
                    </div>
                    <hr/>

                    <div className={css.centerItem}>
                        <button onClick={handleShowSocial} className={css.link}  name='HowToPage'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-share" viewBox="0 0 16 16">
                                <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5m-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3"/>
                            </svg>
                            SHARE
                        </button>
                        {/* {showSocial?
                        <>
                            <FacebookShare round={true} size={40} url={'http://localhost:3000/store/muse%20store'} quote={"My clothing store"} />
                            <TwitterShare round={true} size={40} url={'http://localhost:3000/store/muse%20store'} quote={"My clothing store"} />
                            <LinkedinShare round={true} size={40} url={'http://localhost:3000/store/muse%20store'} quote={"My clothing store"} />
                            <WhatsappShare round={true} size={40} url={'http://localhost:3000/store/muse%20store'} quote={"My clothing store"} />
                        </>
                        :
                        ""} */}
                        
                        
                    </div>
                    <hr/>

                    <div class="container mt-3">
                        
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-bs-toggle="tab" href="#home">Products</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#menu1">Reviews</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#menu2">Vendor Biography</a>
                            </li>
                        </ul>

                        <div class="tab-content">
                            <div id="home" class="container tab-pane active"><br/>
                                <div class={css.warningBg}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
                                    </svg>
                                    <div className={css.row}>

                                            { marketListing !== undefined? Object.values(marketListing).map((data,key)=>{ 
                                                const id  = data?.id?.uuid;
                                                console.log(currentUserId === id);
                                                console.log(id);
                                                console.log(currentUserId);
                                                //if(currentUserId != id){return};
                                                const imgId = marketListing[id]?.relationships?.images?.data[0]?.id?.uuid;
                                                const img = marketImage[imgId]?.attributes?.variants['my-variant']?.url;

                                                return(
                                                    <CustomListingCardComponent data={data} img={img} />
                                                )
                                                
                                            }):""}

                                        
                                    </div>
                                </div>
                            </div>
                            <div id="menu1" class="container tab-pane fade"><br/>
                                <div class={css.warningBg}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
                                    </svg>
                                    Reviews
                                </div>
                            </div>
                            <div id="menu2" class="container tab-pane fade"><br/>
                                <div class={css.warningBg}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
                                    </svg>
                                    {biography}
                                </div>
                            </div>
                            
                        </div>
                    </div>


                    
               </div>

            </div>
       
            
       
    </>
    
  );
};


export default StoreComponent;
