import { updatedEntities, denormalisedEntities } from '../util/data';

// ================ Action types ================ //

export const ADD_CART = 'app/cart/ADD_CART';

// ================ Reducer ================ //

const initialState = {
  // Database of all the fetched cartEntities.
  cartEntities: [],
};

const merge = (state, payload) => {
  const { sdkResponse } = payload;
  const apiResponse = sdkResponse;
  return {
    ...state,
    cartEntities: apiResponse,
  };
};

export default function cartReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case ADD_CART:
      return {
        ...state,
        cartEntities: payload.sdkResponse,
      }

    default:
      return state;
  }
}

// ================ Selectors ================ //

/**
 * Get the denormalised listing cartEntities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getListingsByIdForCounting = (state, listingIds) => {
  const { cartEntities } = state.cart;
  const resources = listingIds.map(id => ({
    id,
    type: 'listing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(cartEntities, resources, throwIfNotFound);
};

/**
 * Get the denormalised cartEntities from the given entity references.
 *
 * @param {Object} state the full Redux store
 *
 * @param {Array<{ id, type }} entityRefs References to cartEntities that
 * we want to query from the data. Currently we expect that all the
 * cartEntities have the same type.
 *
 * @return {Array<Object>} denormalised cartEntities
 */
export const getCart = (state, entityRefs) => {
  const { cartEntities } = state.cart;
  const throwIfNotFound = false;
  return denormalisedEntities(cartEntities, entityRefs, throwIfNotFound);
};

// ================ Action creators ================ //

export const addCart = (sdkResponse) => ({
  type: ADD_CART,
  payload: { sdkResponse },
});
