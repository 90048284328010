import pick from 'lodash/pick';

import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { transactionLineItems } from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import { findNextBoundary, getStartOf, monthIdString } from '../../util/dates';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { getProcess, isBookingProcessAlias } from '../../transactions/transaction';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
export const ASSET_NAME = 'landing-page';
import { util as sdkUtil } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => payload => ({ type: actionType, payload, error: true });

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_INQUIRY_REQUEST = 'app/ListingPage/SEND_INQUIRY_REQUEST';
export const SEND_INQUIRY_SUCCESS = 'app/ListingPage/SEND_INQUIRY_SUCCESS';
export const SEND_INQUIRY_ERROR = 'app/ListingPage/SEND_INQUIRY_ERROR';

export const CREATE_CART_REQUEST = 'app/ListingPage/CREATE_CART_REQUEST';
export const CREATE_CART_SUCCESS = 'app/ListingPage/CREATE_CART_SUCCESS';
export const CREATE_CART_ERROR = 'app/ListingPage/CREATE_CART_ERROR';

export const LOAD_CART_DETAILS_REQUEST = 'app/ListingPage/LOAD_CART_DETAILS_REQUEST';
export const LOAD_CART_DETAILS_SUCCESS = 'app/ListingPage/LOAD_CART_DETAILS_SUCCESS';
export const LOAD_CART_DETAILS_ERROR = 'app/ListingPage/LOAD_CART_DETAILS_ERROR';

export const CREATE_WISHLIST_REQUEST = 'app/ListingPage/CREATE_WISHLIST_REQUEST';
export const CREATE_WISHLIST_SUCCESS = 'app/ListingPage/CREATE_WISHLIST_SUCCESS';
export const CREATE_WISHLIST_ERROR = 'app/ListingPage/CREATE_WISHLIST_ERROR';

export const UPDATE_LOCAL_CART_REQUEST = 'app/ListingPage/UPDATE_LOCAL_CART_REQUEST';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: {
    // '2022-03': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  },
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendInquiryInProgress: false,
  sendInquiryError: null,
  inquiryModalOpenForListingId: null,
  cartData:[],
  createCartInProgress: false,
  createCartError:null,

  cartDataDetails:[],
  loadCartDetailsInProgress: false,
  loadCartDetailsInSuccess:false,
  loadCartDetailsError:null,

  wishlist:[],
  createWishlistInProgress: false,
  createWishlistError:null,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return { ...state, monthlyTimeSlots };
    }

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_INQUIRY_REQUEST:
      return { ...state, sendInquiryInProgress: true, sendInquiryError: null };
    case SEND_INQUIRY_SUCCESS:
      return { ...state, sendInquiryInProgress: false };
    case SEND_INQUIRY_ERROR:
      return { ...state, sendInquiryInProgress: false, sendInquiryError: payload };

    case CREATE_CART_REQUEST:
      return { ...state, createCartInProgress: true, createCartError: null };
    case CREATE_CART_SUCCESS:
      return { ...state, cartData:payload.data.attributes.profile.privateData.cartData, createCartInProgress: false };
    case CREATE_CART_ERROR:
      return { ...state, createCartInProgress: false, createCartError: payload };

    case LOAD_CART_DETAILS_REQUEST:
      return { ...state, loadCartDetailsInProgress: true, loadCartDetailsError: null ,loadCartDetailsInSuccess:false};
    case LOAD_CART_DETAILS_SUCCESS:
      return { ...state, cartDataDetails:payload, loadCartDetailsInProgress: false ,loadCartDetailsInSuccess:true};
    case LOAD_CART_DETAILS_ERROR:
      return { ...state, loadCartDetailsInProgress: false, loadCartDetailsError: payload ,loadCartDetailsInSuccess:false};

      


    case CREATE_WISHLIST_REQUEST:
      return { ...state, createWishlistInProgress: true, createWishlistError: null };
    case CREATE_WISHLIST_SUCCESS:
      return { ...state, wishlist:payload.data.attributes.profile.privateData.wishlist, createWishlistInProgress: false };
    case CREATE_WISHLIST_ERROR:
      return { ...state, createWishlistInProgress: false, createWishlistError: payload };
  
    case UPDATE_LOCAL_CART_REQUEST:
      return { ...state, cartData:payload, createCartInProgress: false };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = monthId => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendInquiryRequest = () => ({ type: SEND_INQUIRY_REQUEST });
export const sendInquirySuccess = () => ({ type: SEND_INQUIRY_SUCCESS });
export const sendInquiryError = e => ({ type: SEND_INQUIRY_ERROR, error: true, payload: e });

//My custom create cart
export const createCartRequest = requestAction(CREATE_CART_REQUEST);
export const createCartSuccess = successAction(CREATE_CART_SUCCESS);
export const createCartError = errorAction(CREATE_CART_ERROR);

//Load cart details
export const loadCartDetailRequest = requestAction(LOAD_CART_DETAILS_REQUEST);
export const loadCartDetailSuccess = successAction(LOAD_CART_DETAILS_SUCCESS);
export const loadCartDetailError = errorAction(LOAD_CART_DETAILS_ERROR);

//My custom create wishlist
export const createWishlistRequest = requestAction(CREATE_WISHLIST_REQUEST);
export const createWishlistSuccess = successAction(CREATE_WISHLIST_SUCCESS);
export const createWishlistError = errorAction(CREATE_WISHLIST_ERROR);

//Update local cart
export const updateLocalCartRequest = errorAction(UPDATE_LOCAL_CART_REQUEST);


// ================ Thunks ================ //

export const showListing = (listingId, config, isOwn = false) => (dispatch, getState, sdk) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images', 'currentStock'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
   'variants.my-variant',
    ],
    "imageVariant.my-variant": sdkUtil.objectQueryString({
          w: 900,
          h: 1200,
          fit: 'scale'
        }),
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      dispatch(addMarketplaceEntities(data, sanitizeConfig));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
  const monthId = monthIdString(start, timeZone);

  dispatch(fetchTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    perPage: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

export const sendInquiry = (listing, message) => (dispatch, getState, sdk) => {
  dispatch(sendInquiryRequest());
  const processAlias = listing?.attributes?.publicData?.transactionProcessAlias;
  if (!processAlias) {
    const error = new Error('No transaction process attached to listing');
    log.error(error, 'listing-process-missing', {
      listingId: listing?.id?.uuid,
    });
    dispatch(sendInquiryError(storableError(error)));
    return Promise.reject(error);
  }

  const listingId = listing?.id;
  const [processName, alias] = processAlias.split('/');
  const transitions = getProcess(processName)?.transitions;

  const bodyParams = {
    transition: transitions.INQUIRE,
    processAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sendInquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendInquiryError(storableError(e)));
      throw e;
    });
};

// Helper function for loadData call.
const fetchMonthlyTimeSlots = (dispatch, listing) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    const unitType = attributes?.publicData?.unitType;
    const timeUnit = unitType === 'hour' ? 'hour' : 'day';
    const nextBoundary = findNextBoundary(new Date(), timeUnit, tz);

    const nextMonth = getStartOf(nextBoundary, 'month', tz, 1, 'months');
    const nextAfterNextMonth = getStartOf(nextMonth, 'month', tz, 1, 'months');

    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

export const fetchTransactionLineItems = ({ orderData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ orderData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        orderData,
      });
    });
};


export const createCart = (data) => (dispatch, getState, sdk) => {

  dispatch(createCartRequest(data));
  console.log("Updating Social Profile" + JSON.stringify(data));


  sdk.currentUser
  .show()
  .then(response => {
    console.log(JSON.stringify(response.data));
    let currentData =  response.data.data.attributes.profile.privateData.cartData;

    const cartData = currentData !== undefined? [...currentData,...data]:[...data];
    sdk.currentUser.updateProfile({
      privateData:{
        cartData
      }
      
    }, {
      expand: true
    }).then(res => {
      // res.data
      //console.log("Creating a cart");
      //console.log(JSON.stringify(res.data));
      const createResponse = res; 
      dispatch(createCartSuccess(createResponse));
      dispatch(fetchCurrentUser());
    }).catch(e => {
      console.log(e);
      return dispatch(createCartError(storableError(e)));
    });

  }) 
  
};


export const removeCartItem = (itemId) => (dispatch, getState, sdk) => {
  dispatch(createCartRequest(itemId));
  
  sdk.currentUser
  .show()
  .then(response => {
   
    const currentData =  response.data.data.attributes.profile.privateData.cartData;
    
    const cartData = currentData.filter(item=>item.id != itemId);
    sdk.currentUser.updateProfile({
      privateData:{
        cartData
      }
      
    }, {
      expand: true
    }).then(res => {
      // res.data
      //console.log("Creating a cart");
      //console.log(JSON.stringify(res.data));
      const createResponse = res; 
      dispatch(createCartSuccess(createResponse));
      dispatch(fetchCurrentUser());
      dispatch(onGetListingsDetails(cartData));
    }).catch(e => {
      console.log(e);
      return dispatch(createCartError(storableError(e)));
    });

  }) 
  
};



export const removeCartItems = () => (dispatch, getState, sdk) => {
  dispatch(createCartRequest());

  const cartData = [];
  sdk.currentUser.updateProfile({
    privateData:{
      cartData
    }
    
  }, {
    expand: true
  }).then(res => {
    // res.data
    //console.log("Creating a cart");
    //console.log(JSON.stringify(res.data));
    const createResponse = res; 
    dispatch(createCartSuccess(createResponse));
    dispatch(fetchCurrentUser());
    dispatch(onGetListingsDetails(cartData));
  }).catch(e => {
    console.log(e);
    return dispatch(createCartError(storableError(e)));
  });

};


export const updateCartOnServer = (id) => (dispatch, getState, sdk) => {
  dispatch(createCartRequest(""));

  console.log("111111111111111111111 "+id);
  const cartData = getState().ListingPage.cartData;
  if(typeof cartData === undefined || cartData.length === 0){return}

  sdk.currentUser.updateProfile({
    privateData:{
      cartData
    }
    
  }, {
    expand: true
  }).then(res => {
    // res.data
    //console.log("Creating a cart");
    //console.log(JSON.stringify(res.data));


    var listingId = new UUID(id);
    sdk.listings.show({id: listingId}).then(res => {
      // res.data contains the response data
      
    });

    const createResponse = res; 
    dispatch(createCartSuccess(createResponse));
    dispatch(fetchCurrentUser());
  }).catch(e => {
    console.log(e);
    return dispatch(createCartError(storableError(e)));
  });
  
};

export const onGetListingsDetails = (cartData) => (dispatch, getState, sdk) => {
  dispatch(loadCartDetailRequest(cartData));

  let details = [];
  let idx = 1;
  cartData.map((val,key)=>{
    sdk.listings.show({
      id:val.id,
      include:['author']
    }).then(res => {
      // res.data contains the response data
      
      details.push(denormalisedResponseEntities(res)[0]);
      
      if(cartData.length === idx){
        let dat = {data:details};
        dispatch(loadCartDetailSuccess(dat));
      }

      idx++;
    }).catch(e => {
      console.log(e);
      return dispatch(loadCartDetailsError(storableError(e)));
    });;
    
  });

};

export const updateQuantity = (listingId,value) => (dispatch, getState, sdk) => {
  dispatch(createCartRequest(itemId));

  console.log("333333333333333333333");
  sdk.currentUser
  .show()
  .then(response => {
   
    const cartData = response.data.data.attributes.profile.privateData.cartData;
    cartData.map((val,key)=>{
      if(val.id === listingId){
        cartData[key].quantity = value;
      }
    });

    sdk.listings.show({id: listingId}).then(res => {
      // res.data contains the response data
      stockCount = response.data.data.attributes.stock;

      sdk.currentUser.updateProfile({
        privateData:{
          cartData
        }
        
      }, {
        expand: true
      }).then(res => {
        // res.data
        //console.log("Creating a cart");
        //console.log(JSON.stringify(res.data));
  
       
  
        const createResponse = res; 
        dispatch(createCartSuccess(createResponse));
        dispatch(fetchCurrentUser());
      }).catch(e => {
        console.log(e);
        return dispatch(createCartError(storableError(e)));
      });





    });


    

  }) 
  
};

export const createWishlist = (data) => (dispatch, getState, sdk) => {

  dispatch(createWishlistRequest(data));
  sdk.currentUser
  .show()
  .then(response => {
    const currentData =  response.data.data.attributes.profile.privateData.wishlist;
    let wishlist = [];
    if(currentData !== undefined){
      wishlist = [...currentData,...data];
    }else{
      wishlist = [...data];
    }
    
    sdk.currentUser.updateProfile({
      privateData:{
        wishlist
      }
      
    }, {
      expand: true
    }).then(res => {
      // res.data
      //console.log("Creating a cart");
      //console.log(JSON.stringify(res.data));
      const createResponse = res; 
      dispatch(createWishlistSuccess(createResponse));
      dispatch(fetchCurrentUser());
    }).catch(e => {
      console.log(e);
      return dispatch(createWishlistError(storableError(e)));
    });

  }) 
  
};


export const removeWishlistItem = (itemId) => (dispatch, getState, sdk) => {

  dispatch(createWishlistRequest(itemId));
 
  sdk.currentUser
  .show()
  .then(response => {
   
    const currentData =  response.data.data.attributes.profile.privateData.wishlist;
    const wishlist = currentData.filter(item=>item.id != itemId);
    sdk.currentUser.updateProfile({
      privateData:{
        wishlist
      }
      
    }, {
      expand: true
    }).then(res => {
      // res.data
      //console.log("Creating a cart");
      //console.log(JSON.stringify(res.data));
      const createResponse = res; 
      dispatch(createWishlistSuccess(createResponse));
      dispatch(fetchCurrentUser());
    }).catch(e => {
      console.log(e);
      return dispatch(createWishlistError(storableError(e)));
    });

  }) 
  
};


export const updateCart = (cartData) => (dispatch, getState, sdk) => {
  dispatch(updateLocalCartRequest(cartData));
};

export const loadData = (params, search, config) =>(dispatch, getState, sdk) => {

  const listingId = new UUID(params.id);

  // Clear old line-items
  dispatch(setInitialValues({ lineItems: null }));

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, config, true));
  }

  return Promise.all([
    dispatch(showListing(listingId, config)),
    dispatch(fetchReviews(listingId)),
  ]).then(response => {
    const listing = response[0].data.data;
    const transactionProcessAlias = listing?.attributes?.publicData?.transactionProcessAlias || '';
    if (isBookingProcessAlias(transactionProcessAlias)) {
      // Fetch timeSlots.
      // This can happen parallel to loadData.
      // We are not interested to return them from loadData call.
      fetchMonthlyTimeSlots(dispatch, listing);
    }

    const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };

    const s = getState();
  
    const {
      aspectWidth = 1,
      aspectHeight = 1,
      variantPrefix = 'listing-card',
    } = config.layout.listingImage;
    const aspectRatio = aspectHeight / aspectWidth;
  
    sdk.listings
    .query({ perPage: 20 ,
      include: ['images'],
      'fields.listing': [
        'title',
        'description',
        'geolocation',
        'price',
        'publicData.listingType',
        'publicData.shopName',
        'publicData.shopUrl',
        'publicData.transactionProcessAlias',
        'publicData.unitType',
        // These help rendering of 'purchase' listings,
        // when transitioning from search page to listing page
        'publicData.pickupEnabled',
        'publicData.shippingEnabled',
      ],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': [
        'variants.scaled-small',
        'variants.scaled-medium',
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
        'variants.my-variant',
      ],

      "imageVariant.my-variant": sdkUtil.objectQueryString({
          w: 900,
          h: 1200,
          fit: 'scale'
        }),

      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    })
    .then(resp => {

          const data = {
            listingId:listingId.uuid,
          }

         
          // try {
          //   const response =  fetch('/api/track-views', {
          //     method: 'POST',
          //     body: JSON.stringify(data),
          //     headers: {
          //       'Content-Type': 'application/json',
          //       Accept: 'application/json',
          //     },
          //   });
        
          //   if (!response.ok) {
          //     throw new Error(`Error! status: ${response.status}`);
          //   }
        
          //   const result =  response.json();
           
          //   console.log(JSON.stringify(result) + "yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy");
          //   dispatch(addMarketplaceEntities(resp));
            
          //   return result;
          // } catch (err) {
          //   return dispatch(onboardError(storableError(err)));
          // }
    
//
    })
    .catch(res => {
      // An error occurred
      console.log(`Request failed with status: ${res.status} ${res.statusText}`);
    });




    dispatch(fetchPageAssets(pageAsset, true));
    return response;
  });

};
