import React, { useState } from 'react';
import { bool, arrayOf, number, shape } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { REVIEW_TYPE_OF_PROVIDER, REVIEW_TYPE_OF_CUSTOMER, propTypes } from '../../util/types';
import { ensureCurrentUser, ensureUser } from '../../util/data';
import { withViewport } from '../../util/uiHelpers';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  Heading,
  H2,
  H4,
  Page,
  AvatarLarge,
  NamedLink,
  ListingCard,
  Reviews,
  ButtonTabNavHorizontal,
  LayoutSideNavigation,
  LayoutSingleColumn,
} from '../../components';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';

import css from './StorePage.module.css';
import StoreComponent from '../../components/CustomSection/Store';


const StorePageComponent = props => {
    
    const { 
      scrollingDisabled, 
      currentUser, 
      userShowError, 
      user, 
      intl,
      entities, 
      listingEntities,
      ...rest } = props;
    
   
    const schemaTitle = "Store";
  
    if (userShowError && userShowError.status === 404) {
      return <NotFoundPage />;
    }
  
  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ProfilePage',
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn
        sideNavClassName={css.aside}
        topbar={<TopbarContainer currentPage="ProfilePage" />}
        
        footer={<FooterContainer />}
      >
        <StoreComponent currentUser={currentUser} entities={entities} listingEntities={listingEntities}/>
      </LayoutSingleColumn>
    </Page>
  );
};

StorePageComponent.defaultProps = {
  currentUser: null,
  user: null,
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
};

StorePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  user: propTypes.user,
  userShowError: propTypes.error,
  queryListingsError: propTypes.error,
  listings: arrayOf(propTypes.listing),
  reviews: arrayOf(propTypes.review),
  queryReviewsError: propTypes.error,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    const { isAuthenticated, loginError, signupError, confirmError } = state.auth;
    const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
    const {
            pageAssetsData: privacyAssetsData,
            inProgress: privacyFetchInProgress,
            error: privacyFetchError,
          } = state.hostedAssets || {};
    const { pageAssetsData: tosAssetsData, inProgress: tosFetchInProgress, error: tosFetchError } = state.hostedAssets || {};
    const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
    const {entities} = state.storeData;
    const {listingEntities} = state.storeListingData;
    //console.log(JSON.stringify(listingEntities))
  
    return { 
       
        currentUser,
        isAuthenticated,
        loginError,
        scrollingDisabled: isScrollingDisabled(state),
        signupError,
        confirmError,
        sendVerificationEmailInProgress,
        sendVerificationEmailError,
        privacyAssetsData,
        privacyFetchInProgress,
        privacyFetchError,
        tosAssetsData,
        tosFetchInProgress,
        tosFetchError,
        pageAssetsData,
        inProgress,
        error ,
        entities,
        listingEntities
      };
  };
  
  
  const mapDispatchToProps = dispatch => ({
    submitLogin: ({ email, password }) => dispatch(login(email, password)),
    submitSignup: params => dispatch(signup(params)),
    submitSingupWithIdp: params => dispatch(signupWithIdp(params)),
    onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
  });
  

const StorePage = compose(
  connect(mapStateToProps,mapDispatchToProps),
  withViewport,
  injectIntl
)(StorePageComponent);

export default StorePage;
