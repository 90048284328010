import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { addStoreEntities } from '../../ducks/storeData.duck';
import { addStoreListingEntities } from '../../ducks/storeListingData.duck';
import { createImageVariantConfig } from '../../util/sdkLoader';
export const ASSET_NAME = 'landing-page';
import { types as sdkTypes } from '../../util/sdkLoader';
const { UUID } = sdkTypes;
import { util as sdkUtil } from '../../util/sdkLoader';

export const loadStoreData =  (params) => (dispatch, getState, sdk) => {
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };
  const storeTitle = params.id;

  let queryParam = "";
    
  if(storeTitle!=undefined && storeTitle != "" && storeTitle != null){
      queryParam = {
        pub_shopUrl:storeTitle,
        perPage: 1 ,
        expand: true,
        include: ['images'],
    };
  }

 const loadStore = sdk.ownListings
  .query(queryParam)
  .then(response => {

    dispatch(addStoreEntities(response));
    console.log("Request Savedd with status:---oooooooooooooooooooooooooooooooooooooooooooo    " + JSON.stringify(response.data.data));
  })
  .catch(res => {
    // An error occurred
    console.log(`Request failed with status: ${res.status} ${res.statusText}`);
  });

return  dispatch(fetchPageAssets(pageAsset, true));
};



export const loadData =  (params, search,config) => (dispatch, getState, sdk) => {
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };
  const storeTitle = params.id;


  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  let queryParam = "";
    
  if(storeTitle!=undefined && storeTitle != "" && storeTitle != null){
      queryParam = {
        pub_shopUrl:storeTitle,
        perPage: 1 ,
        expand: true,
        include: ['images'],
    };
  }

 const loadStore = sdk.listings
  .query(queryParam)
  .then(response => {

    const listingFields = config?.listing?.listingFields;
    const sanitizeConfig = { listingFields };
    
    dispatch(addStoreEntities(response));
    console.log("Request Savedd with status:---oooooooooooooooooooooooooooooooooooooooooooo    " + JSON.stringify(response.data.data));
  })
  .catch(res => {
    // An error occurred
    console.log(`Request failed with status: ${res.status} ${res.statusText}`);
  });


const loadListing = sdk.listings
.query({ 
  pub_storeUrl:storeTitle,
  pub_listingType: ["sell-new-products","digital","rental"],
  perPage: 20 ,
  expand: true,
  include: ['author','images'],
  'fields.listing': [
    'title',
    'description',
    'geolocation',
    'price',
    'publicData.listingType',
    'publicData.transactionProcessAlias',
    'publicData.unitType',
    // These help rendering of 'purchase' listings,
    // when transitioning from search page to listing page
    'publicData.pickupEnabled',
    'publicData.shippingEnabled',
    'pub'
  ],
  'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
  'fields.image': [
    'variants.scaled-small',
    'variants.scaled-medium',
    `variants.${variantPrefix}`,
    `variants.${variantPrefix}-2x`,
  'variants.my-variant',
    ],
    "imageVariant.my-variant": sdkUtil.objectQueryString({
          w: 900,
          h: 1200,
          fit: 'scale'
        }),
  ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
  ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  'limit.images': 5,
})
.then(response => {
  
  //Record store view

  response.data.data = response.data.data.filter(listng=>listng.attributes.publicData.shopUrl === storeTitle);

  dispatch(addStoreListingEntities(response));
  //console.log("Request Savedd with status:---oooooooooooooooooooooooooooooooooooooooooooo    " + JSON.stringify(response.data.data));
})
.catch(res => {
  // An error occurred
  console.log(`Request failed with status: ${res.status} ${res.statusText}`);
});



Promise.all([loadListing,loadStore]).then((values) => {
  console.log("Done ----------------");
 
})


return  dispatch(fetchPageAssets(pageAsset, true));
};
