import omit from 'lodash/omit';

import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import {
  getDefaultTimeZoneOnBrowser,
  getStartOf,
  getStartOfWeek,
  monthIdString,
  parseDateFromISO8601,
  stringifyDateToISO8601,
} from '../../util/dates';
import { uniqueBy } from '../../util/generators';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { parse } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  createStripeAccount,
  updateStripeAccount,
  fetchStripeAccount,
} from '../../ducks/stripeConnectAccount.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { NamedRedirect } from '../../components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const { UUID,Money } = sdkTypes;

// Create array of N items where indexing starts from 1
const getArrayOfNItems = n =>
  Array(n)
    .fill()
    .map((v, i) => i + 1)
    .slice(1);

// Return an array of image ids
const imageIds = images => {
  // For newly uploaded image the UUID can be found from "img.imageId"
  // and for existing listing images the id is "img.id"
  return images ? images.map(img => img.imageId || img.id) : null;
};

// After listing creation & update, we want to make sure that uploadedImages state is cleaned
const updateUploadedImagesState = (state, payload) => {
  const { uploadedImages, uploadedImagesOrder } = state;

  // Images attached to listing entity
  const attachedImages = payload?.data?.relationships?.images?.data || [];
  const attachedImageUUIDStrings = attachedImages.map(img => img.id.uuid);

  // Uploaded images (which are propably not yet attached to listing)
  const unattachedImages = Object.values(state.uploadedImages);
  const duplicateImageEntities = unattachedImages.filter(unattachedImg =>
    attachedImageUUIDStrings.includes(unattachedImg.imageId?.uuid)
  );
  return duplicateImageEntities.length > 0
    ? {
        uploadedImages: {},
        uploadedImagesOrder: [],
      }
    : {
        uploadedImages,
        uploadedImagesOrder,
      };
};

const getImageVariantInfo = listingImageConfig => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = listingImageConfig;
  const aspectRatio = aspectHeight / aspectWidth;
  const fieldsImage = [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`];

  return {
    fieldsImage,
    imageVariants: {
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    },
  };
};

const sortExceptionsByStartTime = (a, b) => {
  return a.attributes.start.getTime() - b.attributes.start.getTime();
};

// When navigating through weekly calendar,
// we want to merge new week-related data (inProgres, error) to weeklyExceptionQueries hashmap.
const mergeToWeeklyExceptionQueries = (weeklyExceptionQueries, weekStartId, newDataProps) => {
  return weekStartId
    ? {
        weeklyExceptionQueries: {
          ...weeklyExceptionQueries,
          [weekStartId]: {
            ...weeklyExceptionQueries[weekStartId],
            ...newDataProps,
          },
        },
      }
    : {};
};
// When navigating through monthly calendar (e.g. when adding a new AvailabilityException),
// we want to merge new month-related data (inProgres, error) to monthlyExceptionQueries hashmap.
const mergeToMonthlyExceptionQueries = (monthlyExceptionQueries, monthId, newDataProps) => {
  return monthId
    ? {
        monthlyExceptionQueries: {
          ...monthlyExceptionQueries,
          [monthId]: {
            ...monthlyExceptionQueries[monthId],
            ...newDataProps,
          },
        },
      }
    : {};
};

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => payload => ({ type: actionType, payload, error: true });

// ================ Action types ================ //
export const ONBOARD_REQUEST =
  'app/Checkout/ONBOARD_REQUEST';
export const ONBOARD_SUCCESS =
  'app/Checkout/ONBOARD_SUCCESS';
export const ONBOARD_ERROR =
  'app/Checkout/ONBOARD_ERROR';

export const RESET_AFTER_DELETE_OWN_LISTING = 'app/EditListingPage/RESET_AFTER_DELETE_OWN_LISTING';

export const MARK_TAB_UPDATED = 'app/EditListingPage/MARK_TAB_UPDATED';
export const CLEAR_UPDATED_TAB = 'app/EditListingPage/CLEAR_UPDATED_TAB';

export const CREATE_LISTING_DRAFT_REQUEST = 'app/EditListingPage/CREATE_LISTING_DRAFT_REQUEST';
export const CREATE_LISTING_DRAFT_SUCCESS = 'app/EditListingPage/CREATE_LISTING_DRAFT_SUCCESS';
export const CREATE_LISTING_DRAFT_ERROR = 'app/EditListingPage/CREATE_LISTING_DRAFT_ERROR';


export const CREATE_LISTING_REQUEST = 'app/EditListingPage/CREATE_LISTING_REQUEST';
export const CREATE_LISTING_SUCCESS = 'app/EditListingPage/CREATE_LISTING_SUCCESS';
export const CREATE_LISTING_ERROR = 'app/EditListingPage/CREATE_LISTING_ERROR';



export const DELETE_LISTING_REQUEST = 'app/EditListingPage/DELETE_LISTING_REQUEST';
export const DELETE_LISTING_SUCCESS = 'app/EditListingPage/DELETE_LISTING_SUCCESS';
export const DELETE_LISTING_ERROR = 'app/EditListingPage/DELETE_LISTING_ERROR';


export const CREATE_STORE_REQUEST = 'app/EditListingPage/CREATE_STORE_REQUEST';
export const CREATE_STORE_SUCCESS = 'app/EditListingPage/CREATE_STORE_SUCCESS';
export const CREATE_STORE_ERROR = 'app/EditListingPage/CREATE_STORE_ERROR';



export const PUBLISH_LISTING_REQUEST = 'app/EditListingPage/PUBLISH_LISTING_REQUEST';
export const PUBLISH_LISTING_SUCCESS = 'app/EditListingPage/PUBLISH_LISTING_SUCCESS';
export const PUBLISH_LISTING_ERROR = 'app/EditListingPage/PUBLISH_LISTING_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/EditListingPage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/EditListingPage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/EditListingPage/UPDATE_LISTING_ERROR';

export const UPDATE_SHIP_STATION_REQUEST = 'app/EditListingPage/UPDATE_SHIP_STATION_REQUEST';
export const UPDATE_SHIP_STATION_SUCCESS = 'app/EditListingPage/UPDATE_SHIP_STATION_SUCCESS';
export const UPDATE_SHIP_STATION_ERROR = 'app/EditListingPage/UPDATE_SHIP_STATION_ERROR';

export const SHOW_LISTINGS_REQUEST = 'app/EditListingPage/SHOW_LISTINGS_REQUEST';
export const SHOW_LISTINGS_SUCCESS = 'app/EditListingPage/SHOW_LISTINGS_SUCCESS';
export const SHOW_LISTINGS_ERROR = 'app/EditListingPage/SHOW_LISTINGS_ERROR';

export const FETCH_EXCEPTIONS_REQUEST = 'app/EditListingPage/FETCH_AVAILABILITY_EXCEPTIONS_REQUEST';
export const FETCH_EXCEPTIONS_SUCCESS = 'app/EditListingPage/FETCH_AVAILABILITY_EXCEPTIONS_SUCCESS';
export const FETCH_EXCEPTIONS_ERROR = 'app/EditListingPage/FETCH_AVAILABILITY_EXCEPTIONS_ERROR';
export const FETCH_EXTRA_EXCEPTIONS_SUCCESS =
  'app/EditListingPage/FETCH_EXTRA_AVAILABILITY_EXCEPTIONS_SUCCESS';

export const ADD_EXCEPTION_REQUEST = 'app/EditListingPage/ADD_AVAILABILITY_EXCEPTION_REQUEST';
export const ADD_EXCEPTION_SUCCESS = 'app/EditListingPage/ADD_AVAILABILITY_EXCEPTION_SUCCESS';
export const ADD_EXCEPTION_ERROR = 'app/EditListingPage/ADD_AVAILABILITY_EXCEPTION_ERROR';

export const DELETE_EXCEPTION_REQUEST = 'app/EditListingPage/DELETE_AVAILABILITY_EXCEPTION_REQUEST';
export const DELETE_EXCEPTION_SUCCESS = 'app/EditListingPage/DELETE_AVAILABILITY_EXCEPTION_SUCCESS';
export const DELETE_EXCEPTION_ERROR = 'app/EditListingPage/DELETE_AVAILABILITY_EXCEPTION_ERROR';

export const SET_STOCK_REQUEST = 'app/EditListingPage/SET_STOCK_REQUEST';
export const SET_STOCK_SUCCESS = 'app/EditListingPage/SET_STOCK_SUCCESS';
export const SET_STOCK_ERROR = 'app/EditListingPage/SET_STOCK_ERROR';

export const UPLOAD_IMAGE_REQUEST = 'app/EditListingPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/EditListingPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/EditListingPage/UPLOAD_IMAGE_ERROR';

export const REMOVE_LISTING_IMAGE = 'app/EditListingPage/REMOVE_LISTING_IMAGE';

export const SAVE_PAYOUT_DETAILS_REQUEST = 'app/EditListingPage/SAVE_PAYOUT_DETAILS_REQUEST';
export const SAVE_PAYOUT_DETAILS_SUCCESS = 'app/EditListingPage/SAVE_PAYOUT_DETAILS_SUCCESS';
export const SAVE_PAYOUT_DETAILS_ERROR = 'app/EditListingPage/SAVE_PAYOUT_DETAILS_ERROR';

// ================ Reducer ================ //

const initialState = {
  // Error instance placeholders for each endpoint
  createListingDraftError: null,
  listingId: null,
  publishListingError: null,
  updateListingError: null,
  updateShipStationSettingsError: null,
  showListingsError: null,
  uploadImageError: null,
  setStockError: null,
  setStockInProgress: false,
  createListingDraftInProgress: false,
  submittedListingId: null,
  redirectToListing: false,
  uploadedImages: {},
  uploadedImagesOrder: [],
  removedImageIds: [],
  addExceptionError: null,
  addExceptionInProgress: false,
  weeklyExceptionQueries: {
    // '2022-12-12': { // Note: id/key is the start of the week in given time zone
    //   fetchExceptionsError: null,
    //   fetchExceptionsInProgress: null,
    // },
  },
  monthlyExceptionQueries: {
    // '2022-12': {
    //   fetchExceptionsError: null,
    //   fetchExceptionsInProgress: null,
    // },
  },
  allExceptions: [],
  deleteExceptionError: null,
  deleteExceptionInProgress: false,
  listingDraft: null,
  updatedTab: null,
  updateInProgress: false,
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
  updateShipStationSettingsSuccess:false,
  onboardError: null,
  onboardInProgress: false,
  onboardUrl:{},
  creatingStoreInProgress:false,
  creatingStoreError:null,
  creatingStoreSuccess:false,
  createListingInSuccess:false,
  createListingInProgress:false,
  createListingError:null,
  deleteListingInSuccess:false,
  deleteListingInProgress:false,
  deleteListingError:null
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case MARK_TAB_UPDATED:
      return { ...state, updatedTab: payload };
    case CLEAR_UPDATED_TAB:
      return { ...state, updatedTab: null, updateListingError: null };

    case RESET_AFTER_DELETE_OWN_LISTING:
      return {
        ...state,
        deleteListingInSuccess: false,
        createListingInSuccess:false,
        deleteListingInProgress: false,
        createListingInProgress:false,
        deleteListingError: null,
      };

      case CREATE_LISTING_DRAFT_REQUEST:
        return {
          ...state,
          createListingDraftInProgress: true,
          createListingDraftError: null,
          submittedListingId: null,
          listingDraft: null,
        };

    case CREATE_LISTING_DRAFT_SUCCESS:
      return {
        ...state,
        ...updateUploadedImagesState(state, payload),
        createListingDraftInProgress: false,
        submittedListingId: payload.data.id,
        listingDraft: payload.data,
      };
    case CREATE_LISTING_DRAFT_ERROR:
      return {
        ...state,
        createListingDraftInProgress: false,
        createListingDraftError: payload,
      };

      case CREATE_LISTING_REQUEST:
        return {
          ...state,
          createListingInProgress: true,
          createListingInSuccess:false,
          createListingError: null,
          submittedListingId: null,
          listingNew: null,
        };
  
      case CREATE_LISTING_SUCCESS:
        return {
          ...state,
          ...updateUploadedImagesState(state, payload),
          createListingInProgress: false,
          createListingInSuccess:true,
          submittedListingId: payload.data.id,
          listingNew: payload.data,
        };
      case CREATE_LISTING_ERROR:
        return {
          ...state,
          createListingInProgress: false,
          createListingInSuccess:false,
          createListingError: payload,
        };



        case DELETE_LISTING_REQUEST:
          return {
            ...state,
            deleteListingInProgress: true,
            deleteListingInSuccess:false,
            deleteListingError: null,
            
          };
    
        case DELETE_LISTING_SUCCESS:
          return {
            ...state,
            
            deleteListingInProgress: false,
            deleteListingInSuccess:true,
            
          };
        case DELETE_LISTING_ERROR:
          return {
            ...state,
            deleteListingInProgress: false,
            deleteListingInSuccess:false,
            deleteListingError: payload,
          };
  










        case CREATE_STORE_REQUEST:
          return {
            ...state,
            creatingStoreInProgress: true,
            creatingStoreSuccess:false,
            creatingStoreError: null,
            
          };
    
        case CREATE_STORE_SUCCESS:
          return {
            ...state,
            
            creatingStoreInProgress: false,
            creatingStoreSuccess:true,
            
            
          };
        case CREATE_STORE_ERROR:
          return {
            ...state,
            creatingStoreInProgress: false,
            creatingStoreSuccess:false,
            creatingStoreError: payload,
          };










    case PUBLISH_LISTING_REQUEST:
      return {
        ...state,
        listingId: payload.listingId,
        publishListingError: null,
      };
    case PUBLISH_LISTING_SUCCESS:
      return {
        ...state,
        redirectToListing: true,
        createListingDraftError: null,
        updateListingError: null,
        showListingsError: null,
        uploadImageError: null,
        createListingDraftInProgress: false,
        updateInProgress: false,
      };
    case PUBLISH_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        publishListingError: {
          listingId: state.listingId,
          error: payload,
        },
      };
    }

    case UPDATE_LISTING_REQUEST:
      return { ...state, updateInProgress: true, updateListingError: null };
    case UPDATE_LISTING_SUCCESS:
      return {
        ...state,
        ...updateUploadedImagesState(state, payload),
        updateInProgress: false,
        // availabilityCalendar: { ...state.availabilityCalendar },
      };
    case UPDATE_LISTING_ERROR:
      return { ...state, updateInProgress: false, updateListingError: payload };

      case UPDATE_SHIP_STATION_REQUEST:
        return { ...state, updateInProgress: true, updateShipStationSettingsError: null };
      case UPDATE_SHIP_STATION_SUCCESS:
        return {
          ...state,
          updateShipStationSettingsSuccess:true,
          updateInProgress: false,
          // availabilityCalendar: { ...state.availabilityCalendar },
        };
      case UPDATE_SHIP_STATION_ERROR:
        return { ...state, updateInProgress: false, updateShipStationSettingsError: payload };

    case SHOW_LISTINGS_REQUEST:
      return { ...state, showListingsError: null };
    case SHOW_LISTINGS_SUCCESS: {
      const listingIdFromPayload = payload.data.id;
      const { listingId, allExceptions, weeklyExceptionQueries, monthlyExceptionQueries } = state;
      // If listing stays the same, we trust previously fetched exception data.
      return listingIdFromPayload?.uuid === state.listingId?.uuid
        ? {
            ...initialState,
            listingId,
            allExceptions,
            weeklyExceptionQueries,
            monthlyExceptionQueries,
          }
        : { ...initialState, listingId: listingIdFromPayload };
    }
    case SHOW_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, showListingsError: payload, redirectToListing: false };

    case FETCH_EXCEPTIONS_REQUEST: {
      const { monthId, weekStartId } = payload.params;
      const newData = { fetchExceptionsError: null, fetchExceptionsInProgress: true };

      const exceptionQueriesMaybe = monthId
        ? mergeToMonthlyExceptionQueries(state.monthlyExceptionQueries, monthId, newData)
        : weekStartId
        ? mergeToWeeklyExceptionQueries(state.weeklyExceptionQueries, weekStartId, newData)
        : {};
      return { ...state, ...exceptionQueriesMaybe };
    }
    case FETCH_EXCEPTIONS_SUCCESS: {
      const { exceptions, monthId, weekStartId } = payload;
      const combinedExceptions = state.allExceptions.concat(exceptions);
      const selectId = x => x.id.uuid;
      const allExceptions = uniqueBy(combinedExceptions, selectId).sort(sortExceptionsByStartTime);
      const newData = { fetchExceptionsInProgress: false };

      const exceptionQueriesMaybe = monthId
        ? mergeToMonthlyExceptionQueries(state.monthlyExceptionQueries, monthId, newData)
        : weekStartId
        ? mergeToWeeklyExceptionQueries(state.weeklyExceptionQueries, weekStartId, newData)
        : {};
      return { ...state, allExceptions, ...exceptionQueriesMaybe };
    }
    case FETCH_EXCEPTIONS_ERROR: {
      const { monthId, weekStartId, error } = payload;
      const newData = { fetchExceptionsInProgress: false, fetchExceptionsError: error };

      const exceptionQueriesMaybe = monthId
        ? mergeToMonthlyExceptionQueries(state.monthlyExceptionQueries, monthId, newData)
        : weekStartId
        ? mergeToWeeklyExceptionQueries(state.weeklyExceptionQueries, weekStartId, newData)
        : {};

      return { ...state, ...exceptionQueriesMaybe };
    }
    case FETCH_EXTRA_EXCEPTIONS_SUCCESS: {
      const combinedExceptions = state.allExceptions.concat(payload.exceptions);
      const selectId = x => x.id.uuid;
      const allExceptions = uniqueBy(combinedExceptions, selectId).sort(sortExceptionsByStartTime);
      // TODO: currently we don't handle thrown errors from these paginated calls
      return { ...state, allExceptions };
    }
    case ADD_EXCEPTION_REQUEST:
      return {
        ...state,
        addExceptionError: null,
        addExceptionInProgress: true,
      };
    case ADD_EXCEPTION_SUCCESS: {
      const exception = payload;
      const combinedExceptions = state.allExceptions.concat(exception);
      const allExceptions = combinedExceptions.sort(sortExceptionsByStartTime);
      return {
        ...state,
        allExceptions,
        addExceptionInProgress: false,
      };
    }
    case ADD_EXCEPTION_ERROR:
      return {
        ...state,
        addExceptionError: payload.error,
        addExceptionInProgress: false,
      };

    case DELETE_EXCEPTION_REQUEST:
      return {
        ...state,
        deleteExceptionError: null,
        deleteExceptionInProgress: true,
      };
    case DELETE_EXCEPTION_SUCCESS: {
      const exception = payload;
      const id = exception.id.uuid;
      const allExceptions = state.allExceptions.filter(e => e.id.uuid !== id);
      return {
        ...state,
        allExceptions,
        deleteExceptionInProgress: false,
      };
    }
    case DELETE_EXCEPTION_ERROR:
      return {
        ...state,
        deleteExceptionError: payload.error,
        deleteExceptionInProgress: false,
      };

    case UPLOAD_IMAGE_REQUEST: {
      // payload.params: { id: 'tempId', file }
      const uploadedImages = {
        ...state.uploadedImages,
        [payload.params.id]: { ...payload.params },
      };
      return {
        ...state,
        uploadedImages,
        uploadedImagesOrder: state.uploadedImagesOrder.concat([payload.params.id]),
        uploadImageError: null,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      // payload.params: { id: 'tempId', imageId: 'some-real-id', attributes, type }
      const { id, ...rest } = payload;
      const uploadedImages = { ...state.uploadedImages, [id]: { id, ...rest } };
      return { ...state, uploadedImages };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      const { id, error } = payload;
      const uploadedImagesOrder = state.uploadedImagesOrder.filter(i => i !== id);
      const uploadedImages = omit(state.uploadedImages, id);
      return { ...state, uploadedImagesOrder, uploadedImages, uploadImageError: error };
    }

    case REMOVE_LISTING_IMAGE: {
      const id = payload.imageId;

      // Only mark the image removed if it hasn't been added to the
      // listing already
      const removedImageIds = state.uploadedImages[id]
        ? state.removedImageIds
        : state.removedImageIds.concat(id);

      // Always remove from the draft since it might be a new image to
      // an existing listing.
      const uploadedImages = omit(state.uploadedImages, id);
      const uploadedImagesOrder = state.uploadedImagesOrder.filter(i => i !== id);

      return { ...state, uploadedImages, uploadedImagesOrder, removedImageIds };
    }

    case SET_STOCK_REQUEST:
      return { ...state, setStockInProgress: true, setStockError: null };
    case SET_STOCK_SUCCESS:
      return { ...state, setStockInProgress: false };
    case SET_STOCK_ERROR:
      return { ...state, setStockInProgress: false, setStockError: payload };

    case SAVE_PAYOUT_DETAILS_REQUEST:
      return { ...state, payoutDetailsSaveInProgress: true };
    case SAVE_PAYOUT_DETAILS_ERROR:
      return { ...state, payoutDetailsSaveInProgress: false };
    case SAVE_PAYOUT_DETAILS_SUCCESS:
      return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsSaved: true };





      case ONBOARD_REQUEST:
        return {
          ...state,
          onboardInProgress: true,
          onboardError: null,
          
        };
      case ONBOARD_SUCCESS:
        return { ...state ,
            onboardInProgress: false,
            onboardUrl:payload,
        };
      case ONBOARD_ERROR:
        console.error(payload); // eslint-disable-line no-console
        return {
          ...state,
          onboardInProgress: false,
          onboardError: payload,
        };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //
  export const onboardRequest = ()=>({type:ONBOARD_REQUEST});
  export const onboardSuccess = (response)=>({
    type:ONBOARD_SUCCESS,
    payload:response,
});
  export const onboardError = (error)=>({ 
    type:ONBOARD_ERROR,
    payload:error,
    error:true,
});


export const markTabUpdated = tab => ({
  type: MARK_TAB_UPDATED,
  payload: tab,
});

export const clearUpdatedTab = () => ({
  type: CLEAR_UPDATED_TAB,
});

export const removeListingImage = imageId => ({
  type: REMOVE_LISTING_IMAGE,
  payload: { imageId },
});

// All the action creators that don't have the {Success, Error} suffix
// take the params object that the corresponding SDK endpoint method
// expects.


export const resetDeleteProgressData = requestAction(RESET_AFTER_DELETE_OWN_LISTING);

// SDK method: ownListings.create
export const createListingDraftRequest = requestAction(CREATE_LISTING_DRAFT_REQUEST);
export const createListingDraftSuccess = successAction(CREATE_LISTING_DRAFT_SUCCESS);
export const createListingDraftError = errorAction(CREATE_LISTING_DRAFT_ERROR);

//My custom create listing
export const createListingRequest = requestAction(CREATE_LISTING_REQUEST);
export const createListingSuccess = successAction(CREATE_LISTING_SUCCESS);
export const createListingError = errorAction(CREATE_LISTING_ERROR);

export const deleteListingRequest = requestAction(DELETE_LISTING_REQUEST);
export const deleteListingSuccess = successAction(DELETE_LISTING_SUCCESS);
export const deleteListingError = errorAction(DELETE_LISTING_ERROR);

//My custom create listing
export const createStoreRequest = requestAction(CREATE_STORE_REQUEST);
export const createStoreSuccess = successAction(CREATE_STORE_SUCCESS);
export const createStoreError = errorAction(CREATE_STORE_ERROR);



// SDK method: ownListings.publish
export const publishListingRequest = requestAction(PUBLISH_LISTING_REQUEST);
export const publishListingSuccess = successAction(PUBLISH_LISTING_SUCCESS);
export const publishListingError = errorAction(PUBLISH_LISTING_ERROR);

// SDK method: ownListings.update
export const updateListingRequest = requestAction(UPDATE_LISTING_REQUEST);
export const updateListingSuccess = successAction(UPDATE_LISTING_SUCCESS);
export const updateListingError = errorAction(UPDATE_LISTING_ERROR);

//My custom update ship station settings
export const updateProfileRequest = requestAction(UPDATE_SHIP_STATION_REQUEST);
export const updateProfileSuccess = successAction(UPDATE_SHIP_STATION_SUCCESS);
export const updateProfileError = errorAction(UPDATE_SHIP_STATION_ERROR);

// SDK method: ownListings.show
export const showListingsRequest = requestAction(SHOW_LISTINGS_REQUEST);
export const showListingsSuccess = successAction(SHOW_LISTINGS_SUCCESS);
export const showListingsError = errorAction(SHOW_LISTINGS_ERROR);

// SDK method: images.upload
export const uploadImageRequest = requestAction(UPLOAD_IMAGE_REQUEST);
export const uploadImageSuccess = successAction(UPLOAD_IMAGE_SUCCESS);
export const uploadImageError = errorAction(UPLOAD_IMAGE_ERROR);

// SDK method: stock.compareAndSet
export const setStockRequest = requestAction(SET_STOCK_REQUEST);
export const setStockSuccess = successAction(SET_STOCK_SUCCESS);
export const setStockError = errorAction(SET_STOCK_ERROR);

// SDK method: availabilityExceptions.query
export const fetchAvailabilityExceptionsRequest = requestAction(FETCH_EXCEPTIONS_REQUEST);
export const fetchAvailabilityExceptionsSuccess = successAction(FETCH_EXCEPTIONS_SUCCESS);
export const fetchAvailabilityExceptionsError = errorAction(FETCH_EXCEPTIONS_ERROR);
// Add extra data from additional pages
export const fetchExtraAvailabilityExceptionsSuccess = successAction(
  FETCH_EXTRA_EXCEPTIONS_SUCCESS
);

// SDK method: availabilityExceptions.create
export const addAvailabilityExceptionRequest = requestAction(ADD_EXCEPTION_REQUEST);
export const addAvailabilityExceptionSuccess = successAction(ADD_EXCEPTION_SUCCESS);
export const addAvailabilityExceptionError = errorAction(ADD_EXCEPTION_ERROR);

// SDK method: availabilityExceptions.delete
export const deleteAvailabilityExceptionRequest = requestAction(DELETE_EXCEPTION_REQUEST);
export const deleteAvailabilityExceptionSuccess = successAction(DELETE_EXCEPTION_SUCCESS);
export const deleteAvailabilityExceptionError = errorAction(DELETE_EXCEPTION_ERROR);

export const savePayoutDetailsRequest = requestAction(SAVE_PAYOUT_DETAILS_REQUEST);
export const savePayoutDetailsSuccess = successAction(SAVE_PAYOUT_DETAILS_SUCCESS);
export const savePayoutDetailsError = errorAction(SAVE_PAYOUT_DETAILS_ERROR);

// ================ Thunk ================ //

export function requestShowListing(actionPayload, config) {
  return (dispatch, getState, sdk) => {
    const imageVariantInfo = getImageVariantInfo(config.layout.listingImage);
    const queryParams = {
      include: ['author', 'images', 'currentStock'],
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
    };

    dispatch(showListingsRequest(actionPayload));
    return sdk.ownListings
      .show({ ...actionPayload, ...queryParams })
      .then(response => {
        // EditListingPage fetches new listing data, which also needs to be added to global data
        dispatch(addMarketplaceEntities(response));
        // In case of success, we'll clear state.EditListingPage (user will be redirected away)
        dispatch(showListingsSuccess(response));
        return response;
      })
      .catch(e => dispatch(showListingsError(storableError(e))));
  };
}

// Set stock if requested among listing update info
export function compareAndSetStock(listingId, oldTotal, newTotal) {
  return (dispatch, getState, sdk) => {
    dispatch(setStockRequest());

    return sdk.stock
      .compareAndSet({ listingId, oldTotal, newTotal }, { expand: true })
      .then(response => {
        // NOTE: compareAndSet returns the stock resource of the listing.
        // We update client app's internal state with these updated API entities.
        dispatch(addMarketplaceEntities(response));
        dispatch(setStockSuccess(response));
      })
      .catch(e => {
        log.error(e, 'update-stock-failed', { listingId, oldTotal, newTotal });
        return dispatch(setStockError(storableError(e)));
      });
  };
}

// Helper function to make compareAndSetStock call if stock update is needed.
const updateStockOfListingMaybe = (listingId, stockTotals, dispatch) => {
  const { oldTotal, newTotal } = stockTotals || {};
  // Note: newTotal and oldTotal must be given, but oldTotal can be null
  const hasStockTotals = newTotal >= 0 && typeof oldTotal !== 'undefined';

  if (listingId && hasStockTotals) {
    return dispatch(compareAndSetStock(listingId, oldTotal, newTotal));
  }
  return Promise.resolve();
};

// Create listing in draft state
// NOTE: we want to keep it possible to include stock management field to the first wizard form.
// this means that there needs to be a sequence of calls:
// create, set stock, show listing (to get updated currentStock entity)
export function requestCreateListingDraft(data, config) {
  return (dispatch, getState, sdk) => {
    dispatch(createListingDraftRequest(data));
    const { stockUpdate, images, ...rest } = data;

    // If images should be saved, create array out of the image UUIDs for the API call
    // Note: in this template, image upload is not happening at the same time as listing creation.
    const imageProperty = typeof images !== 'undefined' ? { images: imageIds(images) } : {};
    const ownListingValues = { ...imageProperty, ...rest };

    const imageVariantInfo = getImageVariantInfo(config.layout.listingImage);
    const queryParams = {
      expand: true,
      include: ['author', 'images', 'currentStock'],
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
    };

    let createDraftResponse = null;
    return sdk.ownListings
      .createDraft(ownListingValues, queryParams)
      .then(response => {
        createDraftResponse = response;
        const listingId = response.data.data.id;
        // If stockUpdate info is passed through, update stock
        return updateStockOfListingMaybe(listingId, stockUpdate, dispatch);
      })
      .then(() => {
        // Modify store to understand that we have created listing and can redirect away
        dispatch(createListingDraftSuccess(createDraftResponse));
        return createDraftResponse;
      })
      .catch(e => {
        log.error(e, 'create-listing-draft-failed', { listingData: data });
        return dispatch(createListingDraftError(storableError(e)));
      });
  };
}

// Update the given tab of the wizard with the given data. This saves
// the data to the listing, and marks the tab updated so the UI can
// display the state.
// NOTE: what comes to stock management, this follows the same pattern used in create listing call
export function requestUpdateListing(tab, data, config) {
  return (dispatch, getState, sdk) => {
    dispatch(updateListingRequest(data));
    const { id, stockUpdate, images, ...rest } = data;

    // If images should be saved, create array out of the image UUIDs for the API call
    const imageProperty = typeof images !== 'undefined' ? { images: imageIds(images) } : {};
    const ownListingUpdateValues = { id, ...imageProperty, ...rest };
    const imageVariantInfo = getImageVariantInfo(config.layout.listingImage);
    const queryParams = {
      expand: true,
      include: ['author', 'images', 'currentStock'],
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
    };

    const state = getState();
    const existingTimeZone =
      state.marketplaceData.entities.ownListing[id.uuid]?.attributes?.availabilityPlan?.timezone;
    const includedTimeZone = rest?.availabilityPlan?.timezone;

    // Note: if update values include stockUpdate, we'll do that first
    // That way we get updated currentStock info among ownListings.update
    return updateStockOfListingMaybe(id, stockUpdate, dispatch)
      .then(() => sdk.ownListings.update(ownListingUpdateValues, queryParams))
      .then(response => {
        dispatch(updateListingSuccess(response));
        dispatch(addMarketplaceEntities(response));
        dispatch(markTabUpdated(tab));

        // If time zone has changed, we need to fetch exceptions again
        // since week and month boundaries might have changed.
        if (!!includedTimeZone && includedTimeZone !== existingTimeZone) {
          const searchString = '';
          const firstDayOfWeek = config.localization.firstDayOfWeek;
          const listing = response.data.data;
          fetchLoadDataExceptions(dispatch, listing, searchString, firstDayOfWeek);
        }

        return response;
      })
      .catch(e => {
        log.error(e, 'update-listing-failed', { listingData: data });
        return dispatch(updateListingError(storableError(e)));
      });
  };
}

export const requestPublishListingDraft = listingId => (dispatch, getState, sdk) => {
  dispatch(publishListingRequest(listingId));

  return sdk.ownListings
    .publishDraft({ id: listingId }, { expand: true })
    .then(response => {
      // Add the created listing to the marketplace data
      dispatch(addMarketplaceEntities(response));
      dispatch(publishListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(publishListingError(storableError(e)));
    });
};

// Images return imageId which we need to map with previously generated temporary id
export function requestImageUpload(actionPayload, listingImageConfig) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    const imageVariantInfo = getImageVariantInfo(listingImageConfig);
    const queryParams = {
      expand: true,
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
    };

    dispatch(uploadImageRequest(actionPayload));
    return sdk.images
      .upload({ image: actionPayload.file }, queryParams)
      .then(resp => {
        const img = resp.data.data;
        // Uploaded image has an existing id that refers to file
        // The UUID was created as a consequence of this upload call - it's saved to imageId property
        return dispatch(
          uploadImageSuccess({ data: { ...img, id, imageId: img.id, file: actionPayload.file } })
        );
      })
      .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}

export const requestAddAvailabilityException = params => (dispatch, getState, sdk) => {
  dispatch(addAvailabilityExceptionRequest(params));

  return sdk.availabilityExceptions
    .create(params, { expand: true })
    .then(response => {
      const availabilityException = response.data.data;
      return dispatch(addAvailabilityExceptionSuccess({ data: availabilityException }));
    })
    .catch(e => {
      dispatch(addAvailabilityExceptionError({ error: storableError(e) }));
      throw e;
    });
};

export const requestDeleteAvailabilityException = params => (dispatch, getState, sdk) => {
  dispatch(deleteAvailabilityExceptionRequest(params));

  return sdk.availabilityExceptions
    .delete(params, { expand: true })
    .then(response => {
      const availabilityException = response.data.data;
      return dispatch(deleteAvailabilityExceptionSuccess({ data: availabilityException }));
    })
    .catch(e => {
      dispatch(deleteAvailabilityExceptionError({ error: storableError(e) }));
      throw e;
    });
};

export const requestFetchAvailabilityExceptions = params => (dispatch, getState, sdk) => {
  const { listingId, start, end, timeZone, page, isWeekly } = params;
  const fetchParams = { listingId, start, end };
  const timeUnitIdProp = isWeekly
    ? { weekStartId: stringifyDateToISO8601(start) }
    : { monthId: monthIdString(start, timeZone) };
  dispatch(fetchAvailabilityExceptionsRequest(timeUnitIdProp));

  return sdk.availabilityExceptions
    .query(fetchParams)
    .then(response => {
      const availabilityExceptions = denormalisedResponseEntities(response);

      // Fetch potential extra exceptions pagination pages per month.
      // In theory, there could be several pagination pages worth of exceptions,
      // if range is month and unit is 'hour': 31 days * 24 hour = 744 slots for exceptions.
      const totalPages = response.data.meta.totalPages;
      if (totalPages > 1 && !page) {
        const extraPages = getArrayOfNItems(totalPages);

        // It's unlikely that this code is reached with default units.
        // Note:
        //  - Firing multiple API calls might hit API rate limit
        //    (This is very unlikely with this query and 'hour' unit.)
        //  - TODO: this doesn't take care of failures of those extra calls
        Promise.all(
          extraPages.map(page => {
            return sdk.availabilityExceptions.query({ ...fetchParams, page });
          })
        ).then(responses => {
          const denormalizedFlatResults = (all, r) => all.concat(denormalisedResponseEntities(r));
          const exceptions = responses.reduce(denormalizedFlatResults, []);
          dispatch(
            fetchExtraAvailabilityExceptionsSuccess({
              data: { ...timeUnitIdProp, exceptions },
            })
          );
        });
      }

      return dispatch(
        fetchAvailabilityExceptionsSuccess({
          data: { ...timeUnitIdProp, exceptions: availabilityExceptions },
        })
      );
    })
    .catch(e => {
      return dispatch(
        fetchAvailabilityExceptionsError({ ...timeUnitIdProp, error: storableError(e) })
      );
    });
};

// Helper function for loadData call.
const fetchLoadDataExceptions = (dispatch, listing, search, firstDayOfWeek) => {
  const hasWindow = typeof window !== 'undefined';
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone = listing?.attributes?.availabilityPlan?.timezone;

  // Fetch time-zones on client side only.
  // Note: listing needs to have time zone set!
  if (hasWindow && listing.id && hasTimeZone) {
    const listingId = listing.id;
    // If the listing doesn't have availabilityPlan yet
    // use the defaul timezone
    const timezone = listing.attributes.availabilityPlan?.timezone || getDefaultTimeZoneOnBrowser();
    const todayInListingsTZ = getStartOf(new Date(), 'day', timezone);

    const locationSearch = parse(search);
    const selectedDate = locationSearch?.d
      ? parseDateFromISO8601(locationSearch.d, timezone)
      : todayInListingsTZ;
    const startOfWeek = getStartOfWeek(selectedDate, timezone, firstDayOfWeek);
    const prevWeek = getStartOf(startOfWeek, 'day', timezone, -7, 'days');
    const nextWeek = getStartOf(startOfWeek, 'day', timezone, 7, 'days');
    const nextAfterNextWeek = getStartOf(nextWeek, 'day', timezone, 7, 'days');

    const nextMonth = getStartOf(todayInListingsTZ, 'month', timezone, 1, 'months');
    const nextAfterNextMonth = getStartOf(nextMonth, 'month', timezone, 1, 'months');

    const sharedData = { listingId, timeZone: timezone };

    // Fetch data for selected week and nearest weeks for WeeklyCalendar
    // Plus current month and month after that for EditListingAvailabilityForm
    //
    // NOTE: This is making 5 different Thunk calls, which update store 2 times each
    //       It would make sense to make on thunk function that fires 5 sdk calls/promises,
    //       but for the time being, it's clearer to push all the calls through
    //       requestFetchAvailabilityExceptions
    return Promise.all([
      dispatch(
        requestFetchAvailabilityExceptions({
          ...sharedData,
          isWeekly: true,
          start: prevWeek,
          end: startOfWeek,
        })
      ),
      dispatch(
        requestFetchAvailabilityExceptions({
          ...sharedData,
          isWeekly: true,
          start: startOfWeek,
          end: nextWeek,
        })
      ),
      dispatch(
        requestFetchAvailabilityExceptions({
          ...sharedData,
          isWeekly: true,
          start: nextWeek,
          end: nextAfterNextWeek,
        })
      ),
      dispatch(
        requestFetchAvailabilityExceptions({
          ...sharedData,
          start: todayInListingsTZ,
          end: nextMonth,
        })
      ),
      dispatch(
        requestFetchAvailabilityExceptions({
          ...sharedData,
          start: nextMonth,
          end: nextAfterNextMonth,
        })
      ),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

export const savePayoutDetails = (values, isUpdateCall) => (dispatch, getState, sdk) => {
  console.log(JSON.stringify(values));
  const upsertThunk = isUpdateCall ? updateStripeAccount : createStripeAccount;
  dispatch(savePayoutDetailsRequest());

  return dispatch(upsertThunk(values, { expand: true }))
    .then(response => {
      dispatch(savePayoutDetailsSuccess());
      return response;
    })
    .catch(() => dispatch(savePayoutDetailsError()));
};


export function requestCreateListingNew(dataMain,selectedFile,selectedFile1,selectedFile2,selectedFile3,selectedFile4,config) {
  return (dispatch, getState, sdk) => {
    console.log("Starting -----------------------------------------");
    dispatch(createListingRequest(dataMain));
    let createResponse = null;

    const uploadStoreImage =  sdk.images.upload({
      image: selectedFile
    }, {
      expand: true
    }).then(res => {
      // res.data
      const dat = {
        id:res.data.data.id.uuid,
        url:res.data.data.attributes.variants.default.url
      }
      return dat;
    
    }).catch(e => {
      console.log(e)
      return dispatch(createListingError(storableError(e)));
    });

    const uploadStoreImage1 =  sdk.images.upload({
      image: selectedFile1
    }, {
      expand: true
    }).then(res => {
      // res.data
      
      const dat = {
        id:res.data.data.id.uuid,
        url:res.data.data.attributes.variants.default.url
      }
      return dat;
     
    }).catch(e => {
      console.log(e)
      return dispatch(createListingError(storableError(e)));
    });

    const uploadStoreImage2 =  sdk.images.upload({
      image: selectedFile2
    }, {
      expand: true
    }).then(res => {
      // res.data
      
      const dat = {
        id:res.data.data.id.uuid,
        url:res.data.data.attributes.variants.default.url
      }
      return dat;
     
    }).catch(e => {
      console.log(e)
      return dispatch(createListingError(storableError(e)));
    });

    
    const uploadStoreImage3 =  sdk.images.upload({
      image: selectedFile3
    }, {
      expand: true
    }).then(res => {
      // res.data
      
      const dat = {
        id:res.data.data.id.uuid,
        url:res.data.data.attributes.variants.default.url
      }
      return dat;
     
    }).catch(e => {
      console.log(e)
      return dispatch(createListingError(storableError(e)));
    });

    const uploadStoreImage4 =  sdk.images.upload({
      image: selectedFile4
    }, {
      expand: true
    }).then(res => {
      // res.data
      
      const dat = {
        id:res.data.data.id.uuid,
        url:res.data.data.attributes.variants.default.url
      }
      return dat;
     
    }).catch(e => {
      console.log(e)
      return dispatch(createListingError(storableError(e)));
    });


    Promise.all([uploadStoreImage,uploadStoreImage1,uploadStoreImage2,uploadStoreImage3,uploadStoreImage4]).then((values) => {
      const createListingResponse = null;
      const vals = values.filter(i=>i!=undefined);

      const imageColors = {
        color0:vals[0] !== undefined?vals[0].url:"",
        color1:vals[1] !== undefined?vals[1].url:"",
        color2:vals[2] !== undefined?vals[2].url:"",
        color3:vals[3] !== undefined?vals[3].url:"",
        color4:vals[4] !== undefined?vals[4].url:"",
      }
        
      ;


      let imgIds = [];
      vals.map((d,k)=>{
        imgIds.push(d.id);
      });

      //Add the uploaded images
      dataMain.images = imgIds;

      //Set the image colors
      dataMain.publicData.imageColors = imageColors;

      //Create the listing
      return sdk.ownListings
      .create(dataMain)
      .then(response => {
        createResponse = response;
        const listingId = response.data.data.id;
        //console.log(listingId + "aaaaaaaaaaaaaaaaaaaaaaaaa");
       
        sdk.stockAdjustments.create({
          listingId: listingId,
          quantity: parseInt(dataMain.publicData.stockStatus)
        }, {
          expand: true,
          include: ["ownListing.currentStock"]
        }).then(res => {
          // res.data
          dispatch(createListingSuccess(createResponse));
          return createResponse;
        });
        // Modify store to understand that we have created listing and can redirect away
      })
      .then(() => {
      
      })
      .catch(e => {
        log.error(e, 'create-listing-draft-failed', { listingData: dataMain });
        return dispatch(createListingError(storableError(e)));
      });




    }).then((res) => {
      // Modify store to understand that we have created listing and can redirect away
      //console.log("Creating listing" + JSON.stringify(res.data.data));
      dispatch(createListingSuccess(res));
      
      return res;
    })
    .catch(e => {
      console.log(e)
      return dispatch(createListingError(storableError(e)));
    });

  };


  }



export function requestUploadNewImage(listingId,imgName,selectedFile, config) {
  return (dispatch, getState, sdk) => {

    //Upload the image and save the details in publicData
    console.log("Starting Image");////////////////////////////////////
    dispatch(createListingRequest(listingId));
    let createResponse = null;
   return sdk.images.upload({
      image: selectedFile
    }, {
      expand: true
    }).then(res => {
      // res.data
      console.log("Uploaded Image");////////////////////////////////////
      const imgId = res.data.data.id.uuid;

      console.log(imgId +"           "+listingId);////////////////////////////////////
      const extraImgs = {
        name:imgName,
        id:imgId
      };
      
     return sdk.ownListings.update({
        id: new UUID(listingId),
        publicData: {
          extraImgs
        },
      }, {
        expand: true,
      }).then(response => {
        createResponse = response;
        console.log(JSON.stringify(response));////////////////////////////////////
        dispatch(createListingSuccess(createResponse));
        return createResponse;
      })
      .catch(e => {
        console.log(JSON.stringify(e));//////
        return dispatch(createListingError(storableError(e)));
      });


    }) .catch(e => {
      console.log(JSON.stringify(e));//////
      return dispatch(createListingError(storableError(e)));
    });
  };
}


export function requestUploadNewImageExtras(listingId,selectedFile,selectedFile1,selectedFile2,selectedFile3,selectedFile4, config) {
  return (dispatch, getState, sdk) => {

//Upload the image and save the details in publicData
console.log("Starting Image");////////////////////////////////////
dispatch(createListingRequest(listingId));

    const uploadStoreImage =  sdk.images.upload({
      image: selectedFile
    }, {
      expand: true
    }).then(res => {
      // res.data
      
      return res.data.data.id.uuid;
    
    }).catch(e => {
      console.log(e)
      return dispatch(createListingError(storableError(e)));
    });

    const uploadStoreImage1 =  sdk.images.upload({
      image: selectedFile1
    }, {
      expand: true
    }).then(res => {
      // res.data
      
      return res.data.data.id.uuid;
     
    }).catch(e => {
      console.log(e)
      return dispatch(createListingError(storableError(e)));
    });

    const uploadStoreImage2 =  sdk.images.upload({
      image: selectedFile2
    }, {
      expand: true
    }).then(res => {
      // res.data
      
      return res.data.data.id.uuid;
     
    }).catch(e => {
      console.log(e)
      return dispatch(createListingError(storableError(e)));
    });

    
    const uploadStoreImage3 =  sdk.images.upload({
      image: selectedFile3
    }, {
      expand: true
    }).then(res => {
      // res.data
      
      return res.data.data.id.uuid;
     
    }).catch(e => {
      console.log(e)
      return dispatch(createListingError(storableError(e)));
    });

    const uploadStoreImage4 =  sdk.images.upload({
      image: selectedFile4
    }, {
      expand: true
    }).then(res => {
      // res.data
      
      return res.data.data.id.uuid;
     
    }).catch(e => {
      console.log(e)
      return dispatch(createListingError(storableError(e)));
    });


    Promise.all([uploadStoreImage,uploadStoreImage1,uploadStoreImage2,uploadStoreImage3,uploadStoreImage4]).then((values) => {
      const createListingResponse = null;
      const vals = values.filter(i=>i!=undefined);
      sdk.ownListings.update({
        id: new UUID(listingId),
        images: vals
      }, {
        expand: true,
        include: ["images"]
      }).then(res => {
        // res.data
        console.log("profile Image Id Added" + JSON.stringify(res.data.data));
        return res;
        
      });

    }).then((res) => {
      // Modify store to understand that we have created listing and can redirect away
      console.log("updating store" + JSON.stringify(res.data.data));
      dispatch(createListingSuccess(res));
      
      return res;
    })
    .catch(e => {
      console.log(e)
      return dispatch(createListingError(storableError(e)));
    });

  };
}


export function requestUpdateExistingListing(id,titlee,descriptionn,pricee,data,config) {
  return (dispatch, getState, sdk) => {
   dispatch(createListingRequest(data));
   sdk.ownListings.update({
    id: new UUID(id),
    description: descriptionn,
    title:titlee,
    price:pricee,
    publicData:
      data
    ,
    
  }, {
    expand: true,
    include: ["images"]
  }).then(res => {
    // res.data
    const createResponse = res;
    sdk.stockAdjustments.create({
      listingId: new UUID(id),
      quantity: data.stockStatus
    }, {
      expand: true,
      include: ["ownListing.currentStock"]
    }).then(res => {
      // res.data
      dispatch(createListingSuccess(createResponse));
      return createResponse;
    });

  }).catch(e => {
    console.log(JSON.stringify(e));
    return dispatch(createListingError(storableError(e)));
  });

  };
}


export function requestCreateStore(dataMain, config) {
  return (dispatch, getState, sdk) => {
    dispatch(createListingRequest(dataMain));
    console.log("Creating store" + JSON.stringify(dataMain));
    let createResponse = null;
    sdk.ownListings
      .create(dataMain)
      .then(response => {
        const createResponse = response;
        const listingId = response.data.data.id;
        console.log(listingId + "aaaaaaaaaaaaaaaaaaaaaaaaa");

      

        // If stockUpdate info is passed through, update stock
        //return updateStockOfListingMaybe(listingId, stockUpdate, dispatch);
      })
      .then(() => {
        // Modify store to understand that we have created listing and can redirect away
        dispatch(createListingSuccess(createResponse));
        return createResponse;
      })
      .catch(e => {
        console.log(e);
        return dispatch(createListingError(storableError(e)));
      });
  };
}

export function requestUpdateProfile(data, config) {
  return (dispatch, getState, sdk) => {
    dispatch(createListingRequest(data));
    console.log("Updating Social Profile" + JSON.stringify(data));
   
      sdk.currentUser.updateProfile({
          data
      }, {
        expand: true
      }).then(res => {
        // res.data
        console.log("Updating Social Profile Success");
        const createResponse = res;
        dispatch(createListingSuccess(createResponse));
      }).catch(e => {
        console.log(e);
        return dispatch(createListingError(storableError(e)));
      });

  };
}


export function updateUserRole(role) {
  return (dispatch, getState, sdk) => {
    dispatch(createListingRequest(role));
    
    const data = {role:role};
   
      sdk.currentUser.updateProfile({
          protectedData:data
      }, {
        expand: true
      }).then(res => {
        // res.data
        console.log("Updating Social Profile Success");
        const createResponse = res;
        dispatch(fetchCurrentUser());
        dispatch(createListingSuccess(createResponse));
        
      }).catch(e => {
        console.log(e);
        return dispatch(createListingError(storableError(e)));
      });

  };
}

export function requestUpdateShipStationSettings(data, config) {
  return (dispatch, getState, sdk) => {
    dispatch(createListingRequest(data));
    console.log("Updating Profile" + JSON.stringify(data));
   
      sdk.currentUser.updateProfile({
        
        publicData: {
          shipEngineSetting:data
        },
       
      }, {
        expand: true
      }).then(res => {
        // res.data
        console.log("Updating Profile Success");
        createResponse = res;
        dispatch(createListingSuccess(createResponse));
      }).catch(e => {
        console.log(e);
        return dispatch(createListingError(storableError(e)));
      });

  };
}


export function requestUpdateStoreSEO(data,facebookImage,twitterImage, config) {
  return (dispatch, getState, sdk) => {
    dispatch(createListingRequest(data));
    console.log("Updating SEO" + JSON.stringify(data));

    const uploadFacebookImage = facebookImage.length != 0? sdk.images.upload({
      image: facebookImage
    }, {
      expand: true
    }).then(res => {
      // res.data
      
      return res.data.data.id.uuid;
     
    }):"";

    const uploadTwitterImage = twitterImage.length != 0 ? sdk.images.upload({
      image: twitterImage
    }, {
      expand: true
    }).then(res => {
      // res.data
      
      return res.data.data.id.uuid;
     
    }):"";

    Promise.all([uploadFacebookImage, uploadTwitterImage]).then((values) => {
      const createResponse = null;
      
      if(values != null){
        data.storeSEO.facebookImageId = values[0];
        data.storeSEO.twitterImageId = values[1];
        console.log("There was an image");
      }
      
      
      sdk.currentUser.updateProfile({
        publicData: {
          storeSEOSettings: data
        },
       
      }, {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }).then(res => {
        console.log("Step 2 -------------------    " + JSON.stringify(res.data));
        console.log(res.data);
        return res;
      })

    }).then((res) => {
      
      console.log("updating store SEO   " + JSON.stringify(res.data));
      dispatch(createListingSuccess(res));
      return res;
    })
    .catch(e => {
      console.log(e)
      return dispatch(createListingError(storableError(e)));
    });

  };
}

export function requestUpdateStoreSEOFacebook(data,facebookImage, config) {
  return (dispatch, getState, sdk) => {
    dispatch(createListingRequest(data));
    console.log("Updating SEO" + JSON.stringify(data));

    const uploadFacebookImage =  sdk.images.upload({
      image: facebookImage
    }, {
      expand: true
    }).then(res => {
      // res.data
      
      return res.data.data.id.uuid;
     
    });

    Promise.all([uploadFacebookImage, uploadTwitterImage]).then((values) => {
      
      if(values != null){
        data.storeSEO.facebookImageId = values[0];
        console.log("There was an image");
      }
      
      
      sdk.currentUser.updateProfile({
        publicData: {
          storeSEOSettings: data
        },
       
      }, {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }).then(res => {
        console.log("Step 2 -------------------    " + JSON.stringify(res.data));
        console.log(res.data);
        return res;
      })

    }).then((res) => {
      
      console.log("updating store SEO   " + JSON.stringify(res.data));
      dispatch(createListingSuccess(res));
      return res;
    })
    .catch(e => {
      console.log(e)
      return dispatch(createListingError(storableError(e)));
    });

  };
}


export function requestUpdateStoreSEOTwitter(data,twitterImage, config) {
  return (dispatch, getState, sdk) => {
    dispatch(createListingRequest(data));
    console.log("Updating SEO" + JSON.stringify(data));

    const uploadFacebookImage =  sdk.images.upload({
      image: twitterImage
    }, {
      expand: true
    }).then(res => {
      // res.data
      
      return res.data.data.id.uuid;
     
    });

    Promise.all([uploadFacebookImage, uploadTwitterImage]).then((values) => {
      
      if(values != null){
        data.storeSEO.twitterImageId = values[0];
        console.log("There was an image");
      }
      
      sdk.currentUser.updateProfile({
        publicData: {
          storeSEOSettings: data
        },
       
      }, {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }).then(res => {
        console.log("Step 2 -------------------    " + JSON.stringify(res.data));
        console.log(res.data);
        return res;
      })

    }).then((res) => {
      
      console.log("updating store SEO   " + JSON.stringify(res.data));
      dispatch(createListingSuccess(res));
      return res;
    })
    .catch(e => {
      console.log(e)
      return dispatch(createListingError(storableError(e)));
    });

  };
}


export function requestUpdateStoreSEOOnly(data, config) {
  console.log("Updating Profile only");
  return (dispatch, getState, sdk) => {
    dispatch(createListingRequest(data));

    sdk.currentUser.updateProfile({
        
      publicData: {
        storeSEOSettings: data
      },
     
    }, {
      expand: true
    }).then(res => {
      // res.data
      console.log("Updating Profile Success");
      createResponse = res;
      dispatch(createListingSuccess(createResponse));
    }).catch(e => {
      console.log(e);
      return dispatch(createListingError(storableError(e)));
    });
   
  };
}

//
export function requestCreateStoreUploadImgPromise(dataMain,selectedFile,selectedProFile, config) {
  return (dispatch, getState, sdk) => {
    dispatch(createListingRequest(dataMain));
   let createResponse = null;

   const createListing = sdk.ownListings
                            .create(dataMain)
                            .then(response => {
                              return response;
                            })
                            
   const uploadStoreImage =  sdk.images.upload({
      image: selectedFile
    }, {
      expand: true
    }).then(res => {
      // res.data
      
      return res.data.data.id.uuid;
     
    });

    const uploadProfileImage =  sdk.images.upload({
      image: selectedProFile
    }, {
      expand: true
    }).then(res => {
      // res.data
      
      return res.data.data.id.uuid;
     
    });


    Promise.all([createListing, uploadStoreImage, uploadProfileImage]).then((values) => {
      const createListingResponse = values[0];
      const listingId = values[0].data.data.id.uuid;
      sdk.ownListings.update({
        id: new UUID(listingId),
        images: [
          new UUID(values[1]),new UUID(values[2])//Image Ids that are being linked
        ]
      }, {
        expand: true,
        include: ["images"]
      }).then(res => {
        // res.data
        console.log("profile Image Id Added" + JSON.stringify(res.data.data));
      });

      console.log(values); // [3, 1337, "foo"]
      return createListingResponse;
    }).then((res) => {
      // Modify store to understand that we have created listing and can redirect away
      dispatch(createListingSuccess(res));
      console.log("updating store" + JSON.stringify(res.data.data));
      return createResponse;
    })
    .catch(e => {
      console.log(e)
      return dispatch(createListingError(storableError(e)));
    });
  };
}

export function updateStoreUploadImgPromise(dataMain,selectedFile,selectedProFile, config) {
  return (dispatch, getState, sdk) => {dispatch(createStoreRequest(dataMain));
    console.log("A image uploading ==================");
  let uploadStoreImage;
  //if(selectedFile != undefined && selectedFile.size>0){
    uploadStoreImage = sdk.images.upload({
      image: selectedFile
    }, {
      expand: true,
    }).then(res => {
      console.log( JSON.stringify(res.data)+"   1 image uploading ==================");
      return res.data;
    }).catch(e => {
      return 0;
    });
  //}else{return "";}

  let uploadProfileImage;
  //if(selectedProFile != undefined && selectedProFile.size>0){

    uploadProfileImage = sdk.images.upload({
      image: selectedProFile
    }, {
      expand: true,
    }).then(res => {
      console.log( JSON.stringify(res.data)+"   2 image uploading ++++++++++++++++++");
      
     return res.data;
    }).catch(e => {
      return 1;
    });
  //}else{return "";}
 
    Promise.all([uploadStoreImage, uploadProfileImage]).then((values) => {
      let storeImage = {};
      let storeProfileImage = {};

      console.log(values);

      if(selectedFile.size>0){
        console.log("00000000000000000000000000000000");
        storeImage = {
          id:values[0]?.data?.id?.uuid ,
          url:values[0]?.data?.attributes?.variants?.default?.url 
        }
      }
      

      if(selectedProFile.size>0){
        console.log("111111111111111111111111111111111111111");
        storeProfileImage = {
          id:values[1]?.data?.id?.uuid ,
          url:values[1]?.data?.attributes?.variants?.default?.url 
        }
      }
     
      if(selectedFile.size>0){
        dataMain.publicData.storeImage = storeImage;
      }
      if(selectedProFile.size>0){
        dataMain.publicData.storeProfileImage = storeProfileImage;
      }
      
      sdk.ownListings.query({}).then(res => {
        // res.data contains the response data
       const store = res.data.data.filter(listing=>listing.attributes.publicData.listingType === "store");

       if(store !== undefined && store !== null && store.length > 0){

        //There is already a store listing, update it
          
        if(storeImage.id && storeProfileImage.id){
          sdk.ownListings.update({
            id: new UUID(store[0].id.uuid),
            publicData: dataMain.publicData,
            images: [
              new UUID(storeImage.id),
              new UUID(storeProfileImage.id)
            ]
          }, {
            expand: true,
            include: ["images"]
          }).then(res => {
            // res.data
            dispatch(createStoreSuccess(res));
            //return res;
          });
        }else if(storeImage.id){
          sdk.ownListings.update({
            id: new UUID(store[0].id.uuid),
            publicData: dataMain.publicData,
            images: [
              new UUID(storeImage.id),
            ]
          }, {
            expand: true,
            include: ["images"]
          }).then(res => {
            // res.data
            dispatch(createStoreSuccess(res));
            //return res;
          });
        }else if(storeProfileImage.id){
          sdk.ownListings.update({
            id: new UUID(store[0].id.uuid),
            publicData: dataMain.publicData,
            images: [
              new UUID(storeProfileImage.id)
            ]
          }, {
            expand: true,
            include: ["images"]
          }).then(res => {
            // res.data
            dispatch(createStoreSuccess(res));
            //return res;
          });

        }else{
          sdk.ownListings.update({
            id: new UUID(store[0].id.uuid),
            publicData: dataMain.publicData,
          }, {
            expand: true,
            include: ["images"]
          }).then(res => {
            // res.data
            dispatch(createStoreSuccess(res));
            //return res;
          });
        }
           
       }else{
          //There is no store listing, create a new one
             // dataMain.publicData.listingType = "store";

             if(storeImage.id && storeProfileImage.id){
              sdk.ownListings.create({
                title: "Store",
                description: "store",
                publicData: dataMain.publicData,
                images: [
                  new UUID(storeImage.id),
                  new UUID(storeProfileImage.id)
                ]
              }, {
                expand: true,
                include: ["images"]
              }).then(res => {
                // res.data
                dispatch(createStoreSuccess(res));
                //return res;
                
              });
            }else if(storeImage.id){
              sdk.ownListings.create({
                title: "Store",
                description: "store",
                publicData: dataMain.publicData,
                images: [
                  new UUID(storeImage.id),
                ]
              }, {
                expand: true,
                include: ["images"]
              }).then(res => {
                // res.data
                dispatch(createStoreSuccess(res));
                //return res;
                
              });
            }else if(storeProfileImage.id){
              sdk.ownListings.create({
                title: "Store",
                description: "store",
                publicData: dataMain.publicData,
                images: [
                  new UUID(storeProfileImage.id)
                ]
              }, {
                expand: true,
                include: ["images"]
              }).then(res => {
                // res.data
                dispatch(createStoreSuccess(res));
                //return res;
                
              });
    
            }else{
              sdk.ownListings.create({
                title: "Store",
                description: "store",
                publicData: dataMain.publicData,
              }, {
                expand: true,
                include: ["images"]
              }).then(res => {
                // res.data
                dispatch(createStoreSuccess(res));
                //return res;
                
              });
            }


              
       }



       sdk.currentUser.updateProfile({
        protectedData: {
          shopUrl: dataMain.publicData.shopUrl
        }
      }, {
        expand: true,
        include: ["profileImage"]
      }).then(res => {
        // res.data
        console.log("Updated shopUrl ================================");
      });




      });


    })
    .catch(e => {
      console.log(e)
      return dispatch(createStoreError(storableError(e)));
    });











































  };
}


export const callPayPalOnboardingApi = (email)=> async(dispatch,getState,sdk)=>{
  dispatch(onboardRequest());

  console.log(email +"===========================");
  try {
    const response = await fetch('/api/v1/api/getToken', {
      method: 'POST',
      body: JSON.stringify(email),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    const url = result.links[0].href;
    console.log(result.links[0].href);
    dispatch(onboardSuccess(result));
    
    return result;
  } catch (err) {
    return dispatch(onboardError(storableError(err)));
  }

}

export const resetDeleteProgData = ()=> async(dispatch,getState,sdk)=>{
  console.log("Reseting data 0000000000000000000000000000000");
    dispatch(resetDeleteProgressData(false));
}

export const callSavePaypalId = (data)=> async(dispatch,getState,sdk)=>{
  //dispatch(createListingRequest(data));

  sdk.currentUser.updateProfile({
      
    privateData: {
      PaypalData: data
    },
   
  }, {
    expand: true
  }).then(res => {
    // res.data
    console.log("Updating Profile Paypal Info Success");
    //createResponse = res;
    //dispatch(createListingSuccess(createResponse));
  }).catch(e => {
    console.log(e);
    //return dispatch(createListingError(storableError(e)));
  });

}


export const requestDeleteOwnListing = (listingId)=> async(dispatch,getState,sdk)=>{
  dispatch(deleteListingRequest(listingId));
  return sdk.ownListings.close({
    id: new UUID(listingId)
  }, {
    expand: true
  }).then(res => {
    // res.data
    console.log("Listing deleted --------------------------------");
    dispatch(deleteListingSuccess(res));
    return res;
  }).catch(e => {
    console.log(e);
    return dispatch(deleteListingError(storableError(e)));
  });

}

// loadData is run for each tab of the wizard. When editing an
// existing listing, the listing must be fetched first.
export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  dispatch(clearUpdatedTab());
  const { id, type } = params;

  if (type === 'new') {
    // No need to listing data when creating a new listing
    return Promise.all([dispatch(fetchCurrentUser())])
      .then(response => {
        const currentUser = getState().user.currentUser;
        if (currentUser && currentUser.stripeAccount) {
          dispatch(fetchStripeAccount());
        }
        return response;
      })
      .catch(e => {
        throw e;
      });
  }

  const payload = { id: new UUID(id) };
  return Promise.all([dispatch(requestShowListing(payload, config)), dispatch(fetchCurrentUser())])
    .then(response => {
      const currentUser = getState().user.currentUser;
      if (currentUser && currentUser.stripeAccount) {
        dispatch(fetchStripeAccount());
      }

      // Because of two dispatch functions, response is an array.
      // We are only interested in the response from requestShowListing here,
      // so we need to pick the first one
      const listing = response[0]?.data?.data;
      const transactionProcessAlias = listing?.attributes?.publicData?.transactionProcessAlias;
      if (listing && isBookingProcessAlias(transactionProcessAlias)) {
        fetchLoadDataExceptions(dispatch, listing, search, config.localization.firstDayOfWeek);
      }

      return response;
    })
    .catch(e => {
      throw e;
    });
};


