import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { addMarketplaceEntities, addMarketplaceEntities2 } from '../../ducks/marketplaceData.duck';
import { createImageVariantConfig } from '../../util/sdkLoader';
export const ASSET_NAME = 'landing-page';
import { types as sdkTypes } from '../../util/sdkLoader';
const { UUID } = sdkTypes;
import { util as sdkUtil } from '../../util/sdkLoader';



export const getAuthorData = (id) => (dispatch, getState, sdk) => {
  var userId = new UUID(id);
  return sdk.users.show({id: userId}).then(res => {
    // res.data contains the response data
    
  });
  
};

export const loadData =  (params, search,config) => (dispatch, getState, sdk) => {
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };
  const userId = new UUID(params.id);
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  //console.log(JSON.stringify(userId)+"=================================");
  //console.log(JSON.stringify(params)+"--------------------------");
  console.log("------------------------------------");
  sdk.listings
  .query({ 
    pub_listingType: ["sell-new-products","digital","rental"],
    perPage: 20 ,
    expand: true,
    include: ['author','images','currentStock'],
    'fields.listing': [
      'title',
      'description',
      'geolocation',
      'price',
      'publicData.color',
      'publicData.listingType',
      'publicData.category',
      'publicData.transactionProcessAlias',
      'publicData.unitType',
      // These help rendering of 'purchase' listings,
      // when transitioning from search page to listing page
      'publicData.pickupEnabled',
      'publicData.shippingEnabled',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': [
      'variants.scaled-small',
      'variants.scaled-medium',
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      'variants.my-variant',
    ],
    "imageVariant.my-variant": sdkUtil.objectQueryString({
          w: 900,
          h: 1200,
          fit: 'scale'
        }),
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 5,
  })
  .then(response => {

    const listingFields = config?.listing?.listingFields;
    const sanitizeConfig = { listingFields };
    
    dispatch(addMarketplaceEntities(response));
    
  })
  .catch(res => {
    // An error occurred
    console.log(`Request failed with status: ${res.status} ${res.statusText}`);
  });


  return  dispatch(fetchPageAssets(pageAsset, true));
  

};
