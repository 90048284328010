import { updatedEntities, denormalisedEntities } from '../util/data';

// ================ Action types ================ //

export const ADD_MARKETPLACE_ENTITIES_FOR_COUNTING = 'app/marketplaceDataForCouting/ADD_MARKETPLACE_ENTITIES_FOR_COUNTING';

// ================ Reducer ================ //

const initialState = {
  // Database of all the fetched entities2.
  entities2: [],
};

const merge = (state, payload) => {
  const { sdkResponse } = payload;
  const apiResponse = sdkResponse;
  console.log("Api response  wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww"+ apiResponse);
  return {
    ...state,
    entities2: apiResponse,
  };
};

export default function marketplaceDataForCountingReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case ADD_MARKETPLACE_ENTITIES_FOR_COUNTING:
      return {
        ...state,
        entities2: payload.sdkResponse,
      }

    default:
      return state;
  }
}

// ================ Selectors ================ //

/**
 * Get the denormalised listing entities2 with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getListingsByIdForCounting = (state, listingIds) => {
  const { entities2 } = state.marketplaceDataForCounting;
  const resources = listingIds.map(id => ({
    id,
    type: 'listing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(entities2, resources, throwIfNotFound);
};

/**
 * Get the denormalised entities2 from the given entity references.
 *
 * @param {Object} state the full Redux store
 *
 * @param {Array<{ id, type }} entityRefs References to entities2 that
 * we want to query from the data. Currently we expect that all the
 * entities2 have the same type.
 *
 * @return {Array<Object>} denormalised entities2
 */
export const getMarketplaceEntitiesForCounting = (state, entityRefs) => {
  const { entities2 } = state.marketplaceDataForCounting;
  const throwIfNotFound = false;
  return denormalisedEntities(entities2, entityRefs, throwIfNotFound);
};

// ================ Action creators ================ //

export const addMarketplaceEntitiesForCounting = (sdkResponse) => ({
  type: ADD_MARKETPLACE_ENTITIES_FOR_COUNTING,
  payload: { sdkResponse },
});
