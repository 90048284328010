import React, { useEffect, useRef, useState } from 'react';
import css from './CustomListingCard.module.css';

import classNames from 'classnames';
import f1 from '../../assets/banner1.png';
import NamedLink from '../NamedLink/NamedLink';
import compare from '../../assets/compare.PNG';
import heart from '../../assets/heart.png';
import mag from '../../assets/magnifying_glass.png';
import { createSlug } from '../../util/urlHelpers';
import { Button, PrimaryButton } from '..';



const CustomListingCardComponent = props =>{
    const activeClassName = 'my-active-class';
    const {
        showSideForm,
        handleHideForm,
        data,
        img,
        handleAddToCart,
        handleAddToWishlist,
        wishlistId,
        createWishlistInProgress,
        createWishlistError,
        stockCount,
        } = props;
    
    //
    const [showCartBtn,setShowCartBtn] = useState(false);
    const stockVal = stockCount === undefined?"Not in stock": stockCount + " in stock";

    const title = data?.attributes?.title;
    const description = data?.attributes?.description;
    const price = data?.attributes?.price?.amount;
    const colors = data?.attributes?.publicData?.color !== undefined?data?.attributes?.publicData?.color:[];
    let id  = data?.id?.uuid;
    const authorId = data?.relationships?.author?.data?.id?.uuid;
    const isWishListItem = wishlistId != undefined && wishlistId.includes(id)?true:false;
    if(id === null || id === undefined){
        id="abcuyuuyu";
    }

    if(title === null || title === undefined){return ""}


    
const handleShowCartBtn = (event)=>{
    setShowCartBtn(true);
    console.log("dddddddddddddddd");
  }
  
  const handleHideCartBtn = (event)=>{
    setShowCartBtn(false);
    console.log("ooooooooooooo");
  }



  return (
    <>
         <div className={css.rowItem}>
                                    <div className={classNames(css.rowItemSub,css.roundEdge)}>
                                    
                                    <div className={css.imgCon}>
                                        <NamedLink 
                                            name="ListingPage"
                                            className={css.loginLink}
                                            params={{ id: id, slug: createSlug({title}) }}
                                        >
                                            <div className={css.imgCon}>
                                                <img className={css.resize} src={img} />
                                            </div>
                                            
                                        </NamedLink>
                                            
                                        </div>
                                        <div className={css.addToCart}>

                                        {showCartBtn?

                                            <Button
                                                onMouseOut={handleHideCartBtn}
                                                className={css.loginLink2}
                                                onClick={event=>handleAddToCart(event,id,title,description,price,1,img,stockVal,stockCount,authorId)}
                                                >
                                                <svg className={css.svgColor} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-cart" viewBox="0 0 16 16">
                                                    <path className={css.svgColor} d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                                                </svg> 
                                            </Button>


                                            :

                                            <Button
                                                onMouseOver={handleShowCartBtn}
                                                className={css.loginLink}
                                                onClick={event=>handleAddToCart(event,id,title,description,price,1,img,stockVal,stockCount,authorId)}
                                                >
                                                ADD TO CART 
                                            </Button>
                                           
                                            }
                                           
                                            
                                        </div>
                                        <div className={css.addSide}>
                                            <NamedLink name="LoginPage" className={css.loginLink}>
                                                <img className={css.resizeIcon} src={compare} />
                                            </NamedLink>
                                            <NamedLink name="LoginPage" className={css.loginLink}>
                                                <img  src={mag} />
                                            </NamedLink>
                                            <Button inProgress={createWishlistInProgress}  onClick={event=>handleAddToWishlist(event,id,title,description,price,1,img)} className={css.loginLink}>

                                                {isWishListItem?
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"/>
                                                    </svg>
                                                    :
                                                    <img  src={heart} />
                                                }
                                                
                                                
                                            </Button>
                                        </div>
                                       
                                    </div>
                                    <div className={css.caption}>
                                        <div className={css.flexRow}>
                                          {Object.values(colors).map((v,k)=>{
                                              return(
                                                <div className={css.colorCircle} style={{backgroundColor:v}}></div>
                                              )
                                          })}
                                          
                                        </div>
                                        <NamedLink 
                                            name="ListingPage"
                                            className={css.loginLink}
                                            params={{ id: id, slug: createSlug({title}) }}
                                        >
                                            <p className={classNames(css.centerItem,css.shortText)}><b>{description}</b></p>
                                            <p className={classNames(css.centerTitile,css.shortText)}>{title}</p>
                                        </NamedLink>
                                        
                                        <p>${price}</p>
                                        <p>{stockVal}</p>

                                        <Button 
                                            
                                            className={css.quickView}
                                        >
                                            QUICK VIEW
                                        </Button>



                                       
                                    </div>
                                </div>
    </>
    
  );
};


export default CustomListingCardComponent;
