import { updatedEntities, denormalisedEntities } from '../util/data';

// ================ Action types ================ //

export const ADD_STORELISTING_ENTITIES = 'app/storeListingData/ADD_STORELISTING_ENTITIES';

// ================ Reducer ================ //

const initialState = {
  // Database of all the fetched listingEntities.
  listingEntities: {},
};

const merge = (state, payload) => {
  const { sdkResponse, sanitizeConfig } = payload;
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    listingEntities: updatedEntities({ ...state.listingEntities }, apiResponse, sanitizeConfig),
  };
};

export default function storeListingDataReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case ADD_STORELISTING_ENTITIES:
      return merge(state, payload);

    default:
      return state;
  }
}

// ================ Selectors ================ //

/**
 * Get the denormalised listing listingEntities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getStoreListingById = (state, listingIds) => {
  const { listingEntities } = state.storeListingData;
  const resources = listingIds.map(id => ({
    id,
    type: 'listing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(listingEntities, resources, throwIfNotFound);
};

/**
 * Get the denormalised listingEntities from the given entity references.
 *
 * @param {Object} state the full Redux store
 *
 * @param {Array<{ id, type }} entityRefs References to listingEntities that
 * we want to query from the data. Currently we expect that all the
 * listingEntities have the same type.
 *
 * @return {Array<Object>} denormalised listingEntities
 */
export const getStoreListingEntities = (state, entityRefs) => {
  const { listingEntities } = state.storeListingData;
  const throwIfNotFound = false;
  return denormalisedEntities(listingEntities, entityRefs, throwIfNotFound);
};

// ================ Action creators ================ //

export const addStoreListingEntities = (sdkResponse, sanitizeConfig) => ({
  type: ADD_STORELISTING_ENTITIES,
  payload: { sdkResponse, sanitizeConfig },
});
